import React from 'react';
import { formatCurrency } from '../../../app/helpers/number';

const RecentlyCompletedOrders = ({ data }) => {
  const renderRow = (data) => {
    return (
      <>
        {data.length === 0 ? (
          <tr className="text-center">
            <td className="py-3" colSpan={4}>
              No data available
            </td>
          </tr>
        ) : (
          data.map((sale) => (
            <tr key={sale.id}>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 font-semibold tracking-wider">
                {sale.user.name}
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 tracking-wider text-palette-primary">
                {sale.items.length}{' '}
                {sale.items.length > 1 ? 'products' : 'product'}
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 tracking-wider">
                {formatCurrency(sale.amount)}
              </td>
            </tr>
          ))
        )}
      </>
    );
  };

  return (
    <div className="min-w-full bg-white py-4 shadow-md">
      <h3 className="px-6 pb-4 text-xl font-semibold">
        Recently Completed Orders
      </h3>
      <div className="-my-2 px-1 -mx-1 py-2 overflow-auto min-w-full">
        <div className="align-middle inline-block min-w-full border-b border-gray-200 overflow-auto max-h-72">
          <table className="min-w-full relative">
            <thead>
              <tr>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Customer Name
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Item
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Total Payment
                </th>
              </tr>
            </thead>
            <tbody>{renderRow(data)}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RecentlyCompletedOrders;
