import React, { useContext, useRef, useState } from 'react';
import Loader from 'react-spinners/MoonLoader';
import { PaperClipIcon, BadgeCheckIcon } from '@heroicons/react/solid';
import {
  fetchUserProfile,
  uploadAvatarImage,
  updateMerchantProfile,
} from '../../../app/repositories/user';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Button from '../../../app/components/common/Button';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../../app/contexts/authContext';
import history from '../../../app/helpers/history';
import CompleteProfileBanner from '../components/CompleteProfileBanner';
import { ModalContext } from '../../../app/contexts/modalContext';
import { formatCurrency, formatNumber } from '../../../app/helpers/number';
import { ToastContext } from '../../../app/contexts/toastContext';

const ProfileDetailsPage = () => {
  const { deleteToken } = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const inputFile = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data, isLoading, isFetching, refetch } = useQuery(
    'profile',
    fetchUserProfile,
    { refetchOnWindowFocus: false }
  );
  const uploadProfileMutation = useMutation(
    (imageUri) => uploadAvatarImage(imageUri),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Upload Avatar Image',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: (data) => {
        showToast('SUCCESS', 'Success', 'Avatar image uploaded successfully');
        queryClient.invalidateQueries('profile');
      },
    }
  );
  const updateProfileMutation = useMutation(
    (formData) => updateMerchantProfile(formData),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Update Profile',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: (data) => {
        showToast('SUCCESS', 'Success', 'Profile updated successfully');
        queryClient.invalidateQueries('profile');
        setEditMode(false);
      },
    }
  );

  const onSubmit = (data) => {
    updateProfileMutation.mutate(data);
  };

  const calculateTotalBalance = (amounts) => {
    return amounts.reduce((prev, cur) => {
      return prev + cur.amount * cur.currency.value;
    }, 0);
  };

  const profile = data?.data.data.profile;

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <CompleteProfileBanner profile={profile.merchantProfile} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white shadow overflow-hidden"
      >
        <div className="px-4 py-5 sm:px-6 flex flex-row items-center justify-between">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Store Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Your store details and information.
            </p>
          </div>
          {editMode ? (
            <div className="flex space-x-2">
              <Button
                type="button"
                title="Cancel"
                mode="outlined-danger"
                onClick={() => setEditMode(false)}
              />
              <Button
                type="submit"
                title="Save Changes"
                isLoading={updateProfileMutation.isLoading}
                loadingColor="primary"
                mode="outlined"
                // onClick={() => setEditMode(true)}
              />
            </div>
          ) : (
            <Button
              title="Edit Store Info"
              mode="outlined"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 grid items-center">
              <dt className="text-sm font-medium text-gray-500">Balance</dt>
              <dd className="mt-1 font-bold text-gray-900 sm:mt-0 sm:col-span-2 flex space-x-6">
                <div>
                  <div className="text-xl flex flex-row items-center space-x-4">
                    {/* // TODO: Change to currency */}
                    <p>{formatCurrency(data?.data.data.profile.balance)}</p>
                    <button
                      disabled
                      className="bg-gray-200 text-gray-100 px-6 py-2 rounded-lg shadow cursor-not-allowed"
                    >
                      Payout
                    </button>
                  </div>
                  <div className="text-xl items-center">
                    {/* // TODO: Change to currency */}
                    {data?.data.data.profile.wallet.amounts.map((wallet) => {
                      return (
                        <p key={wallet.id}>
                          {wallet.currency.symbol} {formatNumber(wallet.amount)}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 grid items-center">
              <dt className="text-sm font-medium text-gray-500">Store Image</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {uploadProfileMutation.isLoading ? (
                  <div className="h-20 w-20 rounded-full border-palette-primary border-2 flex items-center ">
                    <Loader color="#3E74E4" />
                  </div>
                ) : (
                  <img
                    onClick={onButtonClick}
                    className="object-cover h-20 w-20 rounded-full cursor-pointer border-palette-primary border-2"
                    src={profile.avatar}
                    alt="merchant avatar"
                  />
                )}

                <input
                  type="file"
                  accept="image/png,image/jpg,image/jpeg"
                  name="merchantImage"
                  ref={inputFile}
                  onChange={(e) =>
                    uploadProfileMutation.mutate(e.target.files[0])
                  }
                  className="hidden"
                />
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 grid items-center">
              <dt className="text-sm font-medium text-gray-500">Store Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {editMode ? (
                  <input
                    {...register('name', { required: true })}
                    defaultValue={profile.merchantProfile.name}
                    className="block w-full h-full pl-3 pr-10 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                  />
                ) : (
                  <div className="flex flex-row items-center">
                    {profile.merchantProfile.name}
                    {profile.merchantProfile.isVerified ? (
                      <BadgeCheckIcon
                        height="20"
                        className="ml-1"
                        color="#3E74E4"
                      />
                    ) : null}
                  </div>
                )}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 grid items-center">
              <dt className="text-sm font-medium text-gray-500">Country</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {editMode ? (
                  <input
                    {...register('country', { required: true })}
                    defaultValue={profile.merchantProfile.country}
                    className="block w-full h-full pl-3 pr-10 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                  />
                ) : (
                  profile.merchantProfile.country
                )}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 grid items-center">
              <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {editMode ? (
                  <textarea
                    {...register('address', { required: true })}
                    rows={3}
                    defaultValue={profile.merchantProfile.address}
                    className="block w-full h-full pl-3 pr-10 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                  />
                ) : (
                  profile.merchantProfile.address
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Account Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Your account settings and information.
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 grid items-center">
              <dt className="text-sm font-medium text-gray-500">
                Email Address
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {profile.email}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 grid items-center">
              <dt className="text-sm font-medium text-gray-500">
                Phone Number
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="flex flex-row items-center">
                  {profile.contactNumber}
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:px-6 border-t border-gray-200">
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-row">
                {editMode ? (
                  <Button
                    type="button"
                    title="Deactivate"
                    mode="outlined-danger"
                    onClick={() =>
                      showModal('Deactivate Account', 'DeactivateAccount')
                    }
                  />
                ) : (
                  <>
                    <Button
                      type="button"
                      title="Change Password"
                      mode="outlined"
                      onClick={() =>
                        showModal('Change Password', 'ChangePassword')
                      }
                    />
                    <div className="mr-2" />
                    <Button
                      title="Logout"
                      type="button"
                      mode="outlined-danger"
                      onClick={() => {
                        deleteToken();
                        queryClient.clear();
                        history.push('/login');
                      }}
                    />
                  </>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </form>
    </>
  );
};

export default ProfileDetailsPage;
