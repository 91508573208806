import React, { createContext, useEffect, useReducer } from 'react';
import { getToken, getMessaging } from '@firebase/messaging';
import { api } from '../api/api';
import app from '../helpers/firebase';

export const AuthContext = createContext();
const messaging = getMessaging(app);

const initialState = {
  token: null,
  isLoggedIn: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'DeleteToken':
      return { token: null, isLoggedIn: false };
    case 'SetToken':
      return {
        token: action.payload.token,
        isLoggedIn: action.payload.isLoggedIn,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const token = localStorage.getItem('mudarabah-token');

  const checkLoginStatus = () => {
    if (!!token) {
      dispatch({
        type: 'SetToken',
        payload: {
          token,
          isLoggedIn: true,
        },
      });
    } else {
      dispatch({
        type: 'SetToken',
        payload: {
          token: null,
          isLoggedIn: false,
        },
      });
    }
  };

  const deleteToken = () => {
    localStorage.removeItem('mudarabah-token');

    dispatch({ type: 'DeleteToken' });
  };

  const setToken = (token) => {
    localStorage.setItem('mudarabah-token', token);

    dispatch({
      type: 'SetToken',
      payload: {
        token,
        isLoggedIn: true,
      },
    });
  };

  const setPushToken = async () => {
    const token = localStorage.getItem('mudarabah-token');

    getToken(messaging, {
      vapidKey:
        'BA6vpBElI23Ev2t5Ta09IFHtasHocPAtfYQJmdiCdLC4YXUeh8Q3soG9lOpkZATkq0_l0yhMdbvXwIGkq5DlsBo',
    })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...

          try {
            api.post(
              '/users/push-token',
              { pushToken: currentToken },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
          } catch (error) {
            console.error(error);
          }
        } else {
          // Show permission request UI
          console.error(
            'No registration token available. Request permission to generate one.'
          );
          // ...
        }
      })
      .catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
        // ...
      });
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  useEffect(() => {
    if (state.isLoggedIn) {
      setPushToken();
    }
  }, [state.isLoggedIn]);

  return (
    <AuthContext.Provider value={{ state, setToken, deleteToken }}>
      {props.children}
    </AuthContext.Provider>
  );
};
