import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from '../../contexts/modalContext';
import { ToastContext } from '../../contexts/toastContext';
import { deliverOrder } from '../../repositories/sales';
import Button from '../common/Button';

export const DeliverOrderModal = (props) => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const { hideModal } = useContext(ModalContext);
  const { id } = props;
  const [courier, setCourier] = useState('');

  const mutation = useMutation(() => deliverOrder(id, courier), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Update Product',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast('SUCCESS', 'Success', 'Product status updated successfully');
      queryClient.invalidateQueries(['sales']);
      hideModal();
    },
  });

  return (
    <div className="flex-1 flex flex-col justify-between px-4 py-3">
      <div className="flex my-6 items-center justify-between">
        <input
          onChange={(e) => setCourier(e.target.value)}
          id="courier"
          className="block w-full h-full pl-3 pr-10 py-2 text-gray-900 rounded-none placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm border border-gray-400"
          placeholder="Enter courier name"
        />
      </div>
      <Button
        title="Deliver"
        block
        disabled={courier === ''}
        mode="secondary"
        isLoading={mutation.isLoading}
        loadingColor="dark"
        onClick={() => {
          mutation.mutate();
        }}
      />
    </div>
  );
};
