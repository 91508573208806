import { api } from '../api/api';

export const fetchSales = async ({ queryKey, pageParam = 1 }) => {
  const accessToken = localStorage.getItem('mudarabah-token');
  const [_key, { status, limit }] = queryKey;

  console.log(status === '' ? undefined : status)
  return api.get(
    `/merchant/sales`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        status: status === '' ? undefined : status,
        limit,
        page: pageParam
      }
    }
  );
};

export const fetchSaleById = async (id) => {
  const accessToken = localStorage.getItem('mudarabah-token');
  return api.get(`/merchant/sales/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const fetchCartItems = async () => {
  const accessToken = localStorage.getItem('mudarabah-token');
  return api.get(`/merchant/carts`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token}`,
    },
  });
};

export const addProductToCart = async (productId, quantity) => {
  const accessToken = localStorage.getItem('mudarabah-token');

  return api.post(
    `/carts`,
    {
      quantity,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        product_id: productId
      }
    }
  );
};

export const clearCart = async () => {
  const accessToken = localStorage.getItem('mudarabah-token');

  return api.delete(`/merchant/carts`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token}`,
    },
  });
};

export const generateCheckoutQr = async (items) => {
  const accessToken = localStorage.getItem('mudarabah-token');

  const itemsArr = items.map((i) => i.id);
  return api.post(
    `/merchant/checkouts/qrcode`,
    { items: itemsArr },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deliverOrder = async (id, courier) => {
  const accessToken = localStorage.getItem('mudarabah-token');

  return api.patch(
    `/merchant/sales/${id}/deliver`,
    { courier },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
};

export const arriveOrder = async (id, recipient) => {
  const accessToken = localStorage.getItem('mudarabah-token');

  return api.patch(
    `/merchant/sales/${id}/arrived`,
    { recipient },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
};
