import { useStateMachine } from 'little-state-machine';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '../../../app/components/common/Button';
import updateSignupForm from '../../../app/state/actions/updateSignupForm';

const SignupVerificationForm = ({ onPrevious, onSubmit, onSkip }) => {
  const inputFile = useRef(null);
  const { state, actions } = useStateMachine({ updateSignupForm });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  return (
    <main className="mt-4 sm:mt-12">
      <div className="mx-auto max-w-7xl">
        <div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
            <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
              <div className="px-4 py-8 sm:px-10">
                <div className="mt-6">
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div>
                      <h3 className="text-lg leading-5">Verify Your Account</h3>
                      <p className="text-sm text-gray-400">
                        Please provide ID card information to get verified
                        status
                      </p>
                    </div>

                    <div>
                      <label htmlFor="PIC Name" className="sr-only">
                        PIC Name
                      </label>
                      <input
                        {...register('picName', {
                          required: 'PIC name field cannot be empty',
                        })}
                        defaultValue={state.signupFormValues.picName}
                        type="text"
                        name="picName"
                        id="picName"
                        autoComplete="picName"
                        placeholder="Person in Charge Name"
                        className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                      />
                      {errors.picName && (
                        <p className="text-red-600 text-xs">
                          {errors.picName.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="idNumber" className="sr-only">
                        Phone
                      </label>
                      <input
                        {...register('idNumber', {
                          required: 'ID card number field cannot be empty',
                        })}
                        defaultValue={state.signupFormValues.idNumber}
                        type="number"
                        name="idNumber"
                        id="idNumber"
                        autoComplete="idNumber"
                        placeholder="ID Card Number"
                        className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                      />
                      {errors.idNumber && (
                        <p className="text-red-600 text-xs">
                          {errors.idNumber.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="idImage" className="sr-only">
                        Password
                      </label>
                      <div
                        onClick={onButtonClick}
                        className="cursor-pointer block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                      >
                        {state.signupFormValues?.idImagePreview
                          ? 'Change ID Card Image'
                          : 'Choose ID Card Image'}
                      </div>
                      <input
                        ref={inputFile}
                        id="idImage"
                        name="idImage"
                        type="file"
                        accept="image/png,image/jpg,image/jpeg"
                        placeholder="ID Card Image"
                        className="hidden"
                        onChange={(e) => {
                          const objURL = URL.createObjectURL(e.target.files[0]);
                          setValue('idImagePreview', objURL);
                          setValue('idImage', e.target.files[0]);
                          actions.updateSignupForm({
                            idImagePreview: objURL,
                          });
                        }}
                      />
                      {state.signupFormValues.idImagePreview ? (
                        <img
                          className="w-full cursor-pointer border-palette-primary border-2"
                          src={state.signupFormValues.idImagePreview}
                          alt="id card"
                        />
                      ) : null}
                      {/* {errors.password && (
                                <p className="text-red-600 text-xs">
                                  {errors.password.message}
                                </p>
                              )} */}
                    </div>

                    <div className="flex flex-row space-x-3">
                      <Button
                        title="Previous"
                        block
                        mode="outlined"
                        type="button"
                        onClick={onPrevious}

                        // isLoading={mutation.isLoading}
                      />

                      <Button
                        title="Next"
                        block
                        // isLoading={mutation.isLoading}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10">
                <p
                  onClick={onSkip}
                  className="text-sm cursor-pointer leading-5 text-gray-500 text-center hover:underline"
                >
                  Do it later
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignupVerificationForm;
