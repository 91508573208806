import { useStateMachine } from 'little-state-machine';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import Button from '../../../app/components/common/Button';
import { ModalContext } from '../../../app/contexts/modalContext';
import { fetchProductCategories } from '../../../app/repositories/product';
import updateForm from '../../../app/state/actions/updateForm';

const CreateProductMainInfoForm = ({ onSubmit }) => {
  const { state } = useStateMachine({ updateForm });
  const { showModal } = useContext(ModalContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data, isLoading, isFetching, refetch } = useQuery(
    'categories',
    fetchProductCategories
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex-1">
      <div className="flex flex-col space-y-6 mb-12">
        <h2 className="text-xl font-bold mb-3 mt-8">Product Information</h2>
        <div className="flex">
          <label htmlFor="name" className="w-48">
            Product Name
          </label>
          <div className="flex flex-col w-full">
            <input
              {...register('name', {
                required: 'Product name must contains min. 4 characters',
                minLength: {
                  value: 6,
                  message: 'Product name must contains min. 4 characters',
                },
              })}
              defaultValue={state.formValues?.name}
              type="text"
              className="block w-full h-full px-3 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
            />
            {errors.name && (
              <p className="text-red-600 text-xs">{errors.name.message}</p>
            )}
          </div>
        </div>
        <div className="flex">
          <label htmlFor="description" className="w-48">
            Description
          </label>
          <div className="flex flex-col w-full">
            <textarea
              {...register('description', {
                required: 'Product description cannot be empty',
              })}
              defaultValue={state.formValues?.description}
              className="block w-full h-full px-3 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
              rows={8}
            />
            {errors.description && (
              <p className="text-red-600 text-xs">
                {errors.description.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex items-start">
          <div className="w-48">
            <label className="w-48">Category</label>
            <button
              type="button"
              onClick={() => showModal('Add New Category', 'AddNewCategory')}
              className="mt-2 text-sm px-3 py-2 rounded-none leading-none border border-palette-primary border-dashed opacity-80 text-palette-primary hover:text-white hover:bg-palette-primary focus:outline-none"
            >
              Add Category
            </button>
          </div>
          <div className="flex flex-col w-full">
            <select
              defaultValue={state.formValues?.categoryId}
              className="w-full py-2 px-3 rounded-sm text-gray-900 bg-gray-300 focus:outline-none cursor-pointer sm:text-sm appearance-none"
              {...register('categoryId', {
                required: 'Please select a category for this product',
              })}
            >
              <option value="">Select Category</option>
              {data?.data?.data?.categories.map((category) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                );
              })}
            </select>
            {errors.categoryId && (
              <p className="text-red-600 text-xs">
                {errors.categoryId.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex">
          <label htmlFor="isPublic" className="w-48">
            Product Availability
          </label>
          <div className="flex flex-col w-full">
            <div className="flex items-start">
              <div className="flex h-5 items-center">
                <input {...register('isPublic')} defaultValue={state.formValues?.isPublic} id="isPublic" name="isPublic" type="checkbox"
                       className="h-4 w-4 rounded border-gray-300 text-palette-primary focus:ring-palette-primary"/>
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="isPublic" className="font-medium text-gray-700">Is Public</label>
                <p className="text-gray-500">Uncheck if the product is only available privately.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button title="Next" type="submit" />
      </div>
    </form>
  );
};

export default CreateProductMainInfoForm;
