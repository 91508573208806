import React from 'react';
import { formatFullDate } from '../../helpers/datetime';
import { formatCurrency } from '../../helpers/number';

export const ShowCheckoutQrModal = (props) => {
  const { qrCode, total, checkoutTime } = props;

  return (
    <div className="flex my-6 flex-col items-center">
      <p className="text-sm pb-3 font-semibold text-center">
        Scan The QR Code to Checkout
      </p>
      <img className="w-60 h-60" alt="payment qr code" src={qrCode} />
      <h3 className="text-xl pb-4 font-semibold text-center">Checkout Date</h3>
      <h3 className="text-xl pb-6 font-semibold text-center text-palette-secondary">
        {formatFullDate(checkoutTime)}
      </h3>
      <h3 className="text-xl pb-4 font-semibold text-center">Total Price</h3>
      <h3 className="text-xl pb-6 font-semibold text-center text-palette-secondary">
        {formatCurrency(total)}
      </h3>
    </div>
  );
};
