import { useStateMachine } from 'little-state-machine';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '../../../app/components/common/Button';
import countries from '../../../app/helpers/countries';
import updateSignupForm from '../../../app/state/actions/updateSignupForm';

const SignupMerchantForm = ({ onSubmit, onPrevious }) => {
  const inputFile = useRef(null);
  const { state, actions } = useStateMachine({ updateSignupForm });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  return (
    <main className="mt-4 sm:mt-12">
      <div className="mx-auto max-w-7xl">
        <div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
            <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
              <div className="px-4 py-8 sm:px-10">
                <div className="mt-6">
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div>
                      <h3 className="text-lg leading-5">Store Information</h3>
                      <p className="text-sm text-gray-400">
                        Please provide your store information details
                      </p>
                    </div>

                    <div className="flex justify-center">
                      <label htmlFor="name" className="sr-only">
                        Store Image
                      </label>
                      {state.signupFormValues.merchantImagePreview ? (
                        <img
                          onClick={onButtonClick}
                          className="object-cover h-20 w-20 rounded-full cursor-pointer border-palette-primary border-2"
                          src={state.signupFormValues.merchantImagePreview}
                          alt="merchant avatar"
                        />
                      ) : (
                        <div
                          onClick={onButtonClick}
                          className="flex items-center justify-center h-20 w-20 rounded-full cursor-pointer border-palette-primary border-2"
                        >
                          <p className="text-xs text-center p-2">
                            Select Store Image
                          </p>
                        </div>
                      )}

                      <input
                        // defaultValue={state.signupFormValues?.merchantImage}
                        onChange={(e) => {
                          const objURL = URL.createObjectURL(e.target.files[0]);
                          setValue('merchantImagePreview', objURL);
                          setValue('merchantImage', e.target.files[0]);
                          actions.updateSignupForm({
                            merchantImagePreview: objURL,
                          });
                        }}
                        type="file"
                        accept="image/png,image/jpg,image/jpeg"
                        ref={inputFile}
                        className="hidden"
                      />
                    </div>

                    <div>
                      <label htmlFor="name" className="sr-only">
                        Store Name
                      </label>
                      <input
                        {...register('name', {
                          required: 'Store name field cannot be empty',
                        })}
                        defaultValue={state.signupFormValues?.name}
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="name"
                        placeholder="Store Name"
                        className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                      />
                      {errors.name && (
                        <p className="text-red-600 text-xs">
                          {errors.name.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="country" className="sr-only">
                        Country
                      </label>
                      <select
                        {...register('country', {
                          required:
                            'Please select a country your store based on',
                        })}
                        defaultValue={state.signupFormValues?.country}
                        id="address"
                        className="w-full p-3 rounded-sm text-gray-900 bg-gray-300 focus:outline-none cursor-pointer sm:text-sm appearance-none"
                      >
                        <option value="">Select Country</option>
                        {countries.map((country) => {
                          return (
                            <option key={country.code} value={country.name}>
                              {country.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.country && (
                        <p className="text-red-600 text-xs">
                          {errors.country.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="address" className="sr-only">
                        Address
                      </label>
                      <textarea
                        {...register('address', {
                          required: 'Address field cannot be empty',
                        })}
                        defaultValue={state.signupFormValues?.address}
                        rows={4}
                        id="address"
                        name="address"
                        type="text"
                        placeholder="Address"
                        autoComplete="address"
                        className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                      />
                      {errors.address && (
                        <p className="text-red-600 text-xs">
                          {errors.address.message}
                        </p>
                      )}
                    </div>

                    <fieldset>
                      <label htmlFor="paymentType">Accepts Payment Type</label>
                      <div className="mt-2 space-y-4">
                        <div className="flex items-center">
                          <input
                            id="ALL"
                            type="radio"
                            value="ALL"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            {...register('paymentType', {
                              required:
                                'Please select a payment type to accept',
                            })}
                          />
                          <label
                            htmlFor="ALL"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            All
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="FIAT"
                            type="radio"
                            value="FIAT"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            {...register('paymentType', {
                              required:
                                'Please select a payment type to accept',
                            })}
                          />
                          <label
                            htmlFor="FIAT"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            Fiat Currency
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="TOKEN"
                            type="radio"
                            value="TOKEN"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            {...register('paymentType', {
                              required:
                                'Please select a payment type to accept',
                            })}
                          />
                          <label
                            htmlFor="TOKEN"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            Token
                          </label>
                        </div>
                        {errors.paymentType && (
                          <p className="text-red-600 text-xs">
                            {errors.paymentType.message}
                          </p>
                        )}
                      </div>
                    </fieldset>
                    <div className="flex flex-row space-x-3">
                      <Button
                        title="Previous"
                        block
                        mode="outlined"
                        type="button"
                        onClick={onPrevious}

                        // isLoading={mutation.isLoading}
                      />

                      <Button
                        title="Next"
                        block
                        // isLoading={mutation.isLoading}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignupMerchantForm;
