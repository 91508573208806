import { api } from '../api/api';

export const fetchProducts = async ({ queryKey, pageParam }) => {
  const accessToken = localStorage.getItem('mudarabah-token');
  const [_key, { search, limit }] = queryKey;

  return api.get(
    `/merchant/products`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        search,
        limit,
        page: pageParam
      }
    }
  );
};

export const fetchProductById = async ({ queryKey }) => {
  const accessToken = localStorage.getItem('mudarabah-token');

  const [_key, id] = queryKey;
  return api.get(`/merchant/products/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const fetchProductCategories = async () => {
  const accessToken = localStorage.getItem('mudarabah-token');

  return api.get('/categories', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const fetchProductDiscussions = async ({ queryKey }) => {
  const accessToken = localStorage.getItem('mudarabah-token');
  const [_key, id] = queryKey;
  return api.get(`/merchant/products/${id}/discussions`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const replyDiscussion = async (id, referenceId, message) => {
  const accessToken = localStorage.getItem('mudarabah-token');

  return api.post(
    `/merchant/products/${id}/discussions`,
    { message },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        reference_id: referenceId
      }
    }
  );
};

export const fetchProductReviews = async ({ queryKey }) => {
  const accessToken = localStorage.getItem('mudarabah-token');
  const [_key, id] = queryKey;

  return api.get(`/merchant/products/${id}/reviews`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const createProduct = async (formValues) => {
  const accessToken = localStorage.getItem('mudarabah-token');

  // const token = await SecureStore.getItemAsync('jwt-token');

  const {
    name,
    fiatPrice,
    tokenPrice,
    stock,
    description,
    images,
    categoryId,
    isPublic,
  } = formValues;

  const formData = new FormData();
  formData.append('name', name);
  formData.append('fiatPrice', fiatPrice);
  formData.append('tokenPrice', tokenPrice);
  formData.append('stock', stock);
  formData.append('isPublic', isPublic)
  formData.append('description', description);
  formData.append('categoryId', categoryId);

  images.forEach((img, index) => {
    formData.append('images', img);
  });

  return api.post('/merchant/products', formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      enctype: 'multipart/form-data',
    },
  });
};

export const updateProduct = async (formValues) => {
  const accessToken = localStorage.getItem('mudarabah-token');
  // const token = await SecureStore.getItemAsync('jwt-token');

  const {
    id,
    name,
    fiatPrice,
    tokenPrice,
    stock,
    description,
    images,
    categoryId,
    isPublic,
  } = formValues;

  const formData = new FormData();
  formData.append('name', name);
  formData.append('fiatPrice', fiatPrice);
  formData.append('tokenPrice', tokenPrice);
  formData.append('stock', stock);
  formData.append('description', description);
  formData.append('categoryId', categoryId);
  formData.append('isPublic', isPublic);

  images.forEach((img, index) => {
    formData.append('images', img);
  });

  return api.patch(`/merchant/products/${id}`, formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      enctype: 'multipart/form-data',
    },
  });
};

export const createCategory = async (name) => {
  const accessToken = localStorage.getItem('mudarabah-token');

  return api.post(
    `/categories/`,
    { name },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
};

export const updateStock = async (id, stock) => {
  const accessToken = localStorage.getItem('mudarabah-token');

  return api.patch(
    `/merchant/products/${id}/stock`,
    { stock },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
};

export const deleteProduct = async (id) => {
  const accessToken = localStorage.getItem('mudarabah-token');

  return api.delete(`/merchant/products/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};
