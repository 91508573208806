import React, { useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from '../../contexts/modalContext';
import { ToastContext } from '../../contexts/toastContext';
import { createCategory } from '../../repositories/product';
import { updateMerchantProfile } from '../../repositories/user';
import Button from '../common/Button';

export const CompleteProfileModal = ({ profile }) => {
  const inputFile = useRef(null);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { hideModal } = useContext(ModalContext);
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const mutation = useMutation(
    (formValues) => updateMerchantProfile(formValues),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Update Profile',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: () => {
        showToast('SUCCESS', 'Success', 'Profile updated successfully');
        queryClient.invalidateQueries('profile');
        hideModal();
      },
    }
  );
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onSubmit = (formValues) => {
    mutation.mutate(formValues);
  };

  return (
    <div className="flex-1 flex flex-col justify-between px-4 py-3">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <p className="text-sm text-gray-400">
            Please provide ID card and bank account details to get verified
            status and release your earnings
          </p>
        </div>

        <div>
          <label htmlFor="PIC Name" className="sr-only">
            PIC Name
          </label>
          <input
            {...register('picName', {
              required: 'PIC name field cannot be empty',
            })}
            defaultValue={profile.picName}
            type="text"
            name="picName"
            id="picName"
            autoComplete="picName"
            placeholder="Person in Charge Name"
            className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
          />
          {errors.picName && (
            <p className="text-red-600 text-xs">{errors.picName.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="idNumber" className="sr-only">
            Phone
          </label>
          <input
            {...register('idNumber', {
              required: 'ID card number field cannot be empty',
            })}
            defaultValue={profile.idNumber}
            type="number"
            name="idNumber"
            id="idNumber"
            autoComplete="idNumber"
            placeholder="ID Card Number"
            className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
          />
          {errors.idNumber && (
            <p className="text-red-600 text-xs">{errors.idNumber.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="idImage" className="sr-only">
            Password
          </label>
          <div
            onClick={onButtonClick}
            className="cursor-pointer block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
          >
            {watch('idImagePreview', profile?.idImage)
              ? 'Change ID Card Image'
              : 'Choose ID Card Image'}
          </div>
          <input
            ref={inputFile}
            id="idImage"
            name="idImage"
            type="file"
            accept="image/png,image/jpg,image/jpeg"
            placeholder="ID Card Image"
            className="hidden"
            onChange={(e) => {
              const objURL = URL.createObjectURL(e.target.files[0]);
              setValue('idImagePreview', objURL);
              setValue('idImage', e.target.files[0]);
              // actions.updateSignupForm({
              //   idImagePreview: objURL,
              // });
            }}
          />
          {watch('idImagePreview', profile?.idImage) ? (
            <img
              className="w-full cursor-pointer border-palette-primary border-2"
              src={watch('idImagePreview', profile?.idImage)}
              alt="id card"
            />
          ) : null}
          {!watch('idImagePreview', profile?.idImage) && (
            <p className="text-red-600 text-xs">
              Please upload an image of your id card
            </p>
          )}
        </div>

        <div>
          <label htmlFor="bankName" className="sr-only">
            Bank Account Name
          </label>
          <input
            {...register('bankName', {
              required: 'Bank name field cannot be empty',
            })}
            defaultValue={profile?.bankName}
            type="text"
            name="bankName"
            id="bankName"
            autoComplete="bankName"
            placeholder="Bank Name"
            className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
          />
          {errors.bankName && (
            <p className="text-red-600 text-xs">{errors.bankName.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="bankAccountName" className="sr-only">
            Bank Account Name
          </label>
          <input
            {...register('bankAccountName', {
              required: 'Bank account name field cannot be empty',
            })}
            defaultValue={profile?.bankAccountName}
            type="text"
            name="bankAccountName"
            id="bankAccountName"
            autoComplete="bankAccountName"
            placeholder="Bank Account Name"
            className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
          />
          {errors.bankAccountName && (
            <p className="text-red-600 text-xs">
              {errors.bankAccountName.message}
            </p>
          )}
        </div>

        <div>
          <label htmlFor="bankAccountNumber" className="sr-only">
            Bank Account Number
          </label>
          <input
            {...register('bankAccountNumber', {
              required: 'Bank account number field cannot be empty',
            })}
            defaultValue={profile?.bankAccountNumber}
            id="bankAccountNumber"
            name="bankAccountNumber"
            type="text"
            placeholder="Bank Account Number"
            autoComplete="bankAccountNumber"
            className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
          />
          {errors.bankAccountNumber && (
            <p className="text-red-600 text-xs">
              {errors.bankAccountNumber.message}
            </p>
          )}
        </div>

        <div className="flex flex-row space-x-3">
          <Button title="Next" block isLoading={mutation.isLoading} />
        </div>
      </form>
    </div>
  );
};
