import React from 'react';
import Lottie from 'lottie-react';
import emptyAnimation from '../../assets/animations/empty.json';

const EmptyState = ({ message }) => {
  return (
    <div className="text-center">
      <Lottie animationData={emptyAnimation} className="mb-3 w-4/5 mx-auto" />
      <p className="text-lg font-semibold">{message}</p>
    </div>
  );
};

export default EmptyState;
