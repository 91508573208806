const DataCard = ({ title, content }) => {
  return (
    <div className="px-4 py-5 bg-palette-primary shadow rounded-lg overflow-hidden sm:p-6">
      <dt className="text-sm font-medium text-white truncate">{title}</dt>
      <dd className="mt-1 text-xl lg:text-3xl font-semibold text-white">
        {content}
      </dd>
    </div>
  );
};

export default DataCard;
