import { useStateMachine } from 'little-state-machine';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '../../../app/components/common/Button';

const SignupBankInfoForm = ({ onPrevious, onSubmit, onSkip, isSubmitting }) => {
  const { state } = useStateMachine();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  return (
    <main className="mt-4 sm:mt-12">
      <div className="mx-auto max-w-7xl">
        <div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
            <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
              <div className="px-4 py-8 sm:px-10">
                <div className="mt-6">
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div>
                      <h3 className="text-lg leading-5">
                        Bank Account Information
                      </h3>
                      <p className="text-sm text-gray-400">
                        Please provide bank account information for your payment
                        release
                      </p>
                    </div>

                    <div>
                      <label htmlFor="bankName" className="sr-only">
                        Bank Account Name
                      </label>
                      <input
                        {...register('bankName', {
                          required: 'Bank name field cannot be empty',
                        })}
                        defaultValue={state.signupFormValues?.bankName}
                        type="text"
                        name="bankName"
                        id="bankName"
                        autoComplete="bankName"
                        placeholder="Bank Name"
                        className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                      />
                      {errors.bankName && (
                        <p className="text-red-600 text-xs">
                          {errors.bankName.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="bankAccountName" className="sr-only">
                        Bank Account Name
                      </label>
                      <input
                        {...register('bankAccountName', {
                          required: 'Bank account name field cannot be empty',
                        })}
                        defaultValue={state.signupFormValues?.bankAccountName}
                        type="text"
                        name="bankAccountName"
                        id="bankAccountName"
                        autoComplete="bankAccountName"
                        placeholder="Bank Account Name"
                        className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                      />
                      {errors.bankAccountName && (
                        <p className="text-red-600 text-xs">
                          {errors.bankAccountName.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="bankAccountNumber" className="sr-only">
                        Bank Account Number
                      </label>
                      <input
                        {...register('bankAccountNumber', {
                          required: 'Bank account number field cannot be empty',
                        })}
                        defaultValue={state.signupFormValues?.bankAccountNumber}
                        id="bankAccountNumber"
                        name="bankAccountNumber"
                        type="text"
                        placeholder="Bank Account Number"
                        autoComplete="bankAccountNumber"
                        className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                      />
                      {errors.bankAccountNumber && (
                        <p className="text-red-600 text-xs">
                          {errors.bankAccountNumber.message}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-row space-x-3">
                      <Button
                        title="Previous"
                        block
                        mode="outlined"
                        onClick={onPrevious}
                        // isLoading={mutation.isLoading}
                      />

                      <Button title="Next" block isLoading={isSubmitting} />
                    </div>
                  </form>
                </div>
              </div>
              <div className="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10">
                <p
                  onClick={onSkip}
                  className="text-sm cursor-pointer leading-5 text-gray-500 text-center hover:underline"
                >
                  Do it later
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignupBankInfoForm;
