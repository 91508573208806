import React from 'react';

const salesStatuses = [
  { name: 'All', value: '' },
  { name: 'Pending', value: 'PENDING' },
  { name: 'Confirmed', value: 'CONFIRMED' },
  { name: 'On Process', value: 'ON_PROCESS' },
  { name: 'Delivering', value: 'DELIVERING' },
  { name: 'Arrived', value: 'ARRIVED' },
  { name: 'Completed', value: 'COMPLETED' },
  { name: 'Cancelled', value: 'CANCELLED' },
];

const SalesStatusDropdown = ({ onChange, value }) => {
  return (
    <select
      onChange={(e) => onChange(e.target.value)}
      value={value}
      className="py-2 px-3 rounded-sm text-gray-900 bg-gray-200 focus:outline-none cursor-pointer sm:text-sm appearance-none"
    >
      {salesStatuses.map((status, index) => {
        return (
          <option key={index} value={status.value}>
            {status.name}
          </option>
        );
      })}
    </select>
  );
};

export default SalesStatusDropdown;
