import React from 'react';
import logo from '../assets/images/aurachnid_logo.png';

const NavbarLayout = ({ children, ...rest }) => {
  return (
    <div className="flex min-h-screen">
      {/* Notifications Container */}
      <div className="fixed z-10 items-center sm:items-end pointer-events-none justify-end sm:justify-start inset-0 my-6 mx-4 space-y-3 flex flex-col">
        {/* <NotificationToast /> */}
      </div>
      <div className="flex flex-col w-0 flex-1">
        <div className="flex h-16 shadow lg:shadow-none">
          <div className="flex-1 flex">
            <div className="ml-4 w-full flex lg:ml-6">
              <button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline">
                <img className="w-10" src={logo} alt="durian logo" />
              </button>
              {/* <label htmlFor="search_field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600 ml-4">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      />
                    </svg>
                  </div>
                  <input
                    id="search_field"
                    className="block w-full h-full pl-8 pr-3 py-2 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm"
                    placeholder="Search"
                  />
                </div> */}
            </div>
          </div>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-gray-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
            {React.cloneElement(children, { ...rest })}
          </div>
          {/* {showModal()} */}
        </main>
      </div>
    </div>
  );
};

export default NavbarLayout;
