import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from '../../contexts/modalContext';
import { ToastContext } from '../../contexts/toastContext';
import { formatCurrency } from '../../helpers/number';
import { addProductToCart } from '../../repositories/sales';
import Button from '../common/Button';

export const AddToCartModal = (props) => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const { hideModal } = useContext(ModalContext);
  const { id, name, price, stock, images } = props;
  const [quantity, setQuantity] = useState(1);

  const mutation = useMutation(() => addProductToCart(id, quantity), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Add Product to Cart',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast('SUCCESS', 'Success', 'Product added to cart');
      queryClient.invalidateQueries('cart');
      hideModal();
    },
  });

  return (
    <div className="flex-1 flex flex-col justify-between px-4 py-3">
      <div className="flex my-6 items-center justify-between">
        <div className="flex space-x-4 items-center">
          <img
            className="w-28 h-28 object-cover"
            src={images[0]}
            alt={`${name}`}
          />
          <div className="flex flex-col justify-between">
            <div>
              <h4 className="text-lg">{name}</h4>
              <h4 className="text-lg font-semibold">{formatCurrency(price)}</h4>
            </div>
          </div>
        </div>
        <div className="flex">
          <div
            onClick={() => quantity > 1 && setQuantity(quantity - 1)}
            className="w-8 h-8 flex items-center justify-center border cursor-pointer"
          >
            -
          </div>
          <div className="w-8 h-8 flex items-center justify-center border">
            {quantity}
          </div>
          <div
            onClick={() => quantity < stock && setQuantity(quantity + 1)}
            className="w-8 h-8 flex items-center justify-center border cursor-pointer"
          >
            +
          </div>
        </div>
      </div>
      <Button
        mode="secondary"
        block
        type="button"
        title="Add to Cart"
        onClick={() => {
          mutation.mutate();
        }}
        isLoading={mutation.isLoading}
      />
    </div>
  );
};
