import { useStateMachine } from 'little-state-machine';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import Rating from 'react-rating';
import { Link, useParams } from 'react-router-dom';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { ModalContext } from '../../../app/contexts/modalContext';
import history from '../../../app/helpers/history';
import { formatCurrency, formatNumber } from '../../../app/helpers/number';
import { fetchProductById } from '../../../app/repositories/product';
import updateForm from '../../../app/state/actions/updateForm';

const ProductDetailsPage = () => {
  const { id } = useParams();
  const { showModal } = useContext(ModalContext);
  const { actions, state } = useStateMachine({ updateForm });

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['products', id],
    fetchProductById
  );

  const product = data?.data?.data?.product;

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col sm:rounded-lg rounded-none shadow-lg w-full max-w-full sm:max-w-5xl px-8 py-4 sm:px-20 sm:py-10 bg-white">
      <div className="flex-1">
        <div className="flex items-center justify-between my-8">
          <h2 className="text-2xl font-bold">Product Details</h2>
          <div className="flex space-x-2">
            <button
              onClick={() => {
                showModal('Confirm Delete Product', 'DeleteProduct', { id });
              }}
              className="w-24 text-center px-4 py-2 rounded-none border border-red-500 leading-none text-red-500 hover:bg-red-500 hover:text-white focus:outline-none"
            >
              Delete
            </button>
            <button
              onClick={() => {
                // actions.updateForm()
                const productData = {
                  id: product.id,
                  name: product.name,
                  description: product.description,
                  categoryId: product.categoryId,
                  fiatPrice:
                    product.productPrice && product.productPrice.fiatPrice,
                  tokenPrice:
                    product.productPrice && product.productPrice.tokenPrice,
                  stock: product.stock,
                  images: product.images.map((image) => ({ preview: image })),
                  isPublic: product.isPublic
                };
                actions.updateForm(productData);
                history.push(`/products/${id}/edit`);
              }}
              className="w-24 text-center text px-4 py-2 rounded-none border border-palette-secondary leading-none text-palette-secondary hover:bg-palette-secondary hover:text-white focus:outline-none"
            >
              Edit
            </button>
          </div>
        </div>
        <div className="flex flex-col space-y-6 mb-8">
          <div className="flex items-start space-x-4 overflow-scroll">
            {product.images?.map((image) => (
              <img
                key={image}
                className="w-40 h-40 object-cover"
                src={image}
                alt={`${image}`}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col space-y-6 mb-8">
          <div className="flex">
            <h5 className="w-48 font-semibold">Product Name</h5>
            <h5>{product.name}</h5>
          </div>
          <div className="flex">
            <h5 className="w-48 font-semibold">Description</h5>
            <h5 className="flex-1">{product.description}</h5>
          </div>
          <div className="flex">
            <h5 className="w-48 font-semibold">Category</h5>
            <h5>{product.category.name}</h5>
          </div>
          <div className="flex">
            <h5 className="w-48 font-semibold">Availability</h5>
            <h5>{product.isPublic ? 'Public' : 'Private'}</h5>
          </div>
          <div className="flex">
            <h5 className="w-48 font-semibold">Price in Fiat (RM)</h5>
            <h5>
              {product?.productPrice &&
                formatNumber(product?.productPrice?.fiatPrice)}
            </h5>
          </div>
          <div className="flex">
            <h5 className="w-48 font-semibold">Price in Token (AU)</h5>
            <h5>
              {product?.productPrice &&
                formatNumber(product?.productPrice?.tokenPrice)}
            </h5>
          </div>
          <div className="flex">
            <h5 className="w-48 font-semibold">Stock</h5>
            <h5 className="mr-2">{product?.stock}</h5>
            <button
              onClick={() =>
                showModal('Update Stock', 'UpdateStock', { product, refetch })
              }
              className="text px-4 py-1 rounded-none border border-palette-primary border-dashed leading-none text-palette-primary hover:bg-palette-primary hover:text-white focus:outline-none"
            >
              Update Stock
            </button>
          </div>
          <div className="flex">
            <h5 className="w-48 font-semibold">Reviews</h5>
            <Rating
              initialRating={
                product?.reviews.length === 0
                  ? 0
                  : product?.reviews?.reduce((prev, cur) => {
                      return prev + cur.stars;
                    }, 0) / product?.reviews?.length
              }
              readonly
              emptySymbol={
                <i className="fa fa-star-o text-palette-secondary" />
              }
              fullSymbol={<i className="fa fa-star text-palette-secondary" />}
            />
            <h5 className="mx-2">({product?.reviews.length} reviews)</h5>
            {product?.reviews.length !== 0 ? (
              <Link
                to={`/products/${id}/reviews`}
                className="text px-4 py-1 rounded-none border border-palette-primary border-dashed leading-none text-palette-primary hover:bg-palette-primary hover:text-white focus:outline-none"
              >
                View Reviews
              </Link>
            ) : null}
          </div>
          <div className="flex">
            <h5 className="w-48 font-semibold">Discussions</h5>
            <h5 className="mr-2">{product?.discussions.length} discussions</h5>
            {product?.discussions.length !== 0 ? (
              <Link
                to={`/products/${id}/discussions`}
                className="text px-4 py-1 rounded-none border border-palette-primary border-dashed leading-none text-palette-primary hover:bg-palette-primary hover:text-white focus:outline-none"
              >
                View Discussions
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsPage;
