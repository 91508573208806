import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const ProductFileUpload = ({ onChange, value }) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
    maxFiles: 5,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      onChange(acceptedFiles);
    },
  });

  useEffect(() => {
    if (value) {
      setFiles(value);
    }
  }, [value]);

  const thumbs = files.map((file) => (
    <div
      key={file.preview}
      className="w-40 h-40 border overflow-hidden my-6 relative"
    >
      <img
        className="absolute m-auto inset-0"
        src={file.preview}
        alt={`${file.name}`}
      />
    </div>
  ));

  return (
    <>
      <div
        {...getRootProps({
          className:
            'flex flex-col w-full h-20 border border-black border-dashed justify-center cursor-pointer',
        })}
      >
        <input {...getInputProps()} />
        <p className="text-center ">
          Drag and drop some files here, or click to select files
        </p>
      </div>
      <aside className="flex flex-wrap space-x-3 w-full justify-center">
        {thumbs}
      </aside>
    </>
  );
};

export default ProductFileUpload;
