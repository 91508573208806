// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, onMessage } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBrWwkb0YnX3383pP53Uaw4hBSKC-mShI4',
  authDomain: 'aurachnid-d6b54.firebaseapp.com',
  projectId: 'aurachnid-d6b54',
  storageBucket: 'aurachnid-d6b54.appspot.com',
  messagingSenderId: '19308781383',
  appId: '1:19308781383:web:3ef6be917c9d164aab7662',
  measurementId: 'G-F9Q6XWHN3C',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

onMessage(function (payload) {
  navigator.serviceWorker
    .getRegistration('/firebase-cloud-messaging-push-scope')
    .then((registration) => {
      registration.showNotification(
        payload.notification.title,
        payload.notification
      );
    });
});

export default app;
