import React, { useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { fetchSales } from '../../../app/repositories/sales';
import SalesStatusDropdown from '../components/SalesStatusDropdown';
import SalesTable from '../components/SalesTable';

const SalesPage = () => {
  let sales = [];
  const [status, setStatus] = useState('');
  // const { data, isLoading, isFetching, refetch } = useQuery(
  //   ['sales', { status, page }],
  //   fetchSales,
  //   {
  //     onSuccess: (data) => {
  //       if (data?.data.data.sales.length === 10) {
  //         setPage(page + 1);
  //       }
  //     },
  //   }
  // );
  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(['sales', { status }], fetchSales, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.data.sales.length === 10) {
        return pages.length + 1;
      } else {
        return undefined;
      }
    },
  });

  const salesPages = data?.pages.map((page) => page?.data?.data?.sales);
  salesPages?.forEach((i) => sales.push(...i));

  return (
    <div className="flex flex-col bg-white shadow-md">
      <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 justify-between sm:items-center px-8 pt-6 pb-2">
        <div className="flex flex-col">
          <label htmlFor="status" className="w-48">
            Status
          </label>
          <SalesStatusDropdown
            value={status}
            onChange={(status) => {
              setStatus(status);
              // refetch();
            }}
          />
        </div>
      </div>

      {isLoading ? (
        <div className="py-60">
          <LoadingIndicator relative />
        </div>
      ) : (
        <SalesTable
          sales={sales}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      )}
      {isFetchingNextPage ? (
        <div className="relative -mt-10 bg-palette-primary opacity-80 text-white text-center py-2">
          Loading more data...
        </div>
      ) : null}
    </div>
  );
};

export default SalesPage;
