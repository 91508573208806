import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { fetchProducts } from '../../../app/repositories/product';

const ProductSearchBox = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState(query);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [query]);

  useEffect(() => {
    onSearch(debouncedQuery);
  }, [debouncedQuery]);

  return (
    <div className="relative w-full sm:max-w-sm text-gray-400 focus-within:text-gray-600 mr-6 flex-1 border border-palette-primary">
      <label htmlFor="productSearch" className="sr-only">
        Search products
      </label>
      <div className="flex items-center">
        <input
          id="productSearch"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="block w-full h-full px-3 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm "
          placeholder="Search products"
        />
        {query === '' ? (
          <svg className="h-5 w-5 mr-3" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            />
          </svg>
        ) : (
          <svg
            onClick={() => setQuery('')}
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mr-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        )}
      </div>

      {/* <button
        onClick={(e) => {}}
        className="absolute text-white w-8 inset-y-0 right-0 rounded-none bg-palette-primary focus:outline-none"
      >
        <svg className="h-5 w-5 ml-1" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          />
        </svg>
      </button> */}
    </div>
  );
};

export default ProductSearchBox;
