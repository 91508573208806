import React from 'react';

import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { formatCurrency, formatNumber } from '../../../app/helpers/number';

const ProductTable = ({ products, fetchNextPage, hasNextPage }) => {
  const renderRow = (products) => {
    return (
      <>
        {products.length === 0 ? (
          <tr>
            <td className="text-center p-3" colSpan={6}>
              <p>No data available</p>
            </td>
          </tr>
        ) : (
          products.map((product, index) => (
            <tr key={product.id}>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 font-semibold tracking-wider">
                <div className="flex items-center space-x-3">
                  <img
                    className="w-16 h-16 object-cover"
                    src={product.images && product.images[0]}
                    alt={product.name}
                  />
                  <p>{product.name}</p>
                </div>
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 tracking-wider">
                <p>
                  {product?.productPrice &&
                    formatNumber(product?.productPrice?.fiatPrice)}
                </p>
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 tracking-wider">
                <p>
                  {product?.productPrice &&
                    formatNumber(product?.productPrice?.tokenPrice)}
                </p>
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 tracking-wider">
                <p>{product.stock}</p>
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 tracking-wider">
                <p>
                  {product?.reviews.length === 0
                    ? '0.0'
                    : (
                        product?.reviews?.reduce((prev, cur) => {
                          return prev + cur.stars;
                        }, 0) / product?.reviews?.length
                      ).toFixed(1)}
                </p>
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 tracking-wider font-semibold text-palette-secondary">
                <p>{product.stock > 0 ? 'Active' : 'Out of Stock'}</p>
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 tracking-wider font-semibold text-palette-primary">
                <Link to={`/products/${product.id}`}>Details</Link>
                <Waypoint
                  onEnter={() => {
                    if ((index + 1) % 10 === 0 && hasNextPage) {
                      fetchNextPage();
                    }
                  }}
                />
              </td>
            </tr>
          ))
        )}
      </>
    );
  };

  return (
    <div className="min-w-full bg-white py-4 shadow-md">
      <div className="py-2 overflow-auto min-w-full">
        <div className="align-middle inline-block min-w-full border-b border-gray-200 overflow-auto max-h-2/3-screen">
          <table className="min-w-full relative">
            <thead>
              <tr>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Product
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Price in Fiat (RM)
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Price in Token (AU)
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Stock
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Rating
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Status
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody className="overflow-scroll-y">{renderRow(products)}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductTable;
