import React, { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from '../../contexts/modalContext';
import Button from '../common/Button';
import { deleteProduct } from '../../repositories/product';
import history from '../../helpers/history';
import { deactivateAccount } from '../../repositories/user';
import { ToastContext } from '../../contexts/toastContext';

export const DeactivateAccountModal = () => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const { hideModal } = useContext(ModalContext);
  const mutation = useMutation(() => deactivateAccount(), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Deactivate Account',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast('SUCCESS', 'Success', 'Account deactivated');
      queryClient.invalidateQueries('profile');
      hideModal();
    },
  });

  return (
    <div className="flex-1 flex flex-col justify-between px-4 py-3">
      <div className="flex my-6 items-center justify-between">
        <p>Are you sure you want to deactivate your account?</p>
      </div>
      <div>
        <Button
          title="Deactivate Account"
          mode="outlined-danger"
          isLoading={mutation.isLoading}
          block
          loadingColor="dark"
          onClick={() => mutation.mutate()}
        />
        <div className="my-3" />
        <Button
          title="Cancel"
          mode="contained"
          block
          onClick={() => hideModal()}
        />
      </div>
    </div>
  );
};
