import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from '../../contexts/modalContext';
import { ToastContext } from '../../contexts/toastContext';
import { createCategory } from '../../repositories/product';
import Button from '../common/Button';

export const AddNewCategoryModal = () => {
  const [categoryName, setCategoryName] = useState('');
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { hideModal } = useContext(ModalContext);
  const mutation = useMutation(() => createCategory(categoryName), {
    onError: (error) => {
      console.error(error);
      showToast(
        'ERROR',
        'Failed to Create New Category',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast('SUCCESS', 'Success', 'Product category created successfully');
      queryClient.invalidateQueries('categories');
      hideModal();
    },
  });

  return (
    <div className="flex-1 flex flex-col justify-between px-4 py-3">
      <div className="flex my-6 items-center justify-between">
        <input
          onChange={(e) => setCategoryName(e.target.value)}
          id="categoryName"
          className="block w-full h-full pl-3 pr-10 py-2 text-gray-900 rounded-none placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm border border-gray-400"
          placeholder="Enter category name"
        />
      </div>
      <Button
        title="Save"
        block
        disabled={categoryName === ''}
        mode="secondary"
        isLoading={mutation.isLoading}
        loadingColor="dark"
        onClick={() => {
          mutation.mutate();
        }}
      />
    </div>
  );
};
