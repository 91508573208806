import { DateTime } from "luxon";

export const getTimeAgo = (date) => {
  return DateTime.fromISO(date).toRelative();
};

export const getDuration = (startDate, endDate) => {
  return (
    DateTime.fromISO(startDate).diff(DateTime.fromISO(endDate), "days").days *
    -1
  );
};

export const formatFullDate = (date) => {
  return DateTime.fromISO(date).toFormat("DDD HH:mm");
};

export const formatGigTimeDuration = (startTime, endTime) => {
  return `${DateTime.fromISO(startTime).toLocaleString(
    DateTime.TIME_24_SIMPLE
  )} - ${DateTime.fromISO(endTime).toLocaleString(DateTime.TIME_24_SIMPLE)}`;
};

export const formatDateForPosting = (date) => {
  return DateTime.fromISO(date).toLocaleString({ locale: "en-gb" });
};

export const formatToAge = (date) => {
  return Math.floor(DateTime.fromISO(date).diffNow("years").years * -1);
};
