import { useStateMachine } from 'little-state-machine';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '../../../app/components/common/Button';
import updateForm from '../../../app/state/actions/updateForm';
import { AuthContext } from '../../../app/contexts/authContext';
import { fetchUserProfile } from '../../../app/repositories/user';
import { useQuery } from 'react-query';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';

const CreateProductSalesInfoForm = ({ onSubmit, onPrevious }) => {
  const { state } = useStateMachine({ updateForm });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { data, isLoading, isFetching, refetch } = useQuery(
    'profile',
    fetchUserProfile,
    { refetchOnWindowFocus: false }
  );

  if (isLoading) return <LoadingIndicator />;

  const profile = data?.data.data.profile;

  console.log(profile?.merchantProfile?.paymentType === 'FIAT');

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex-1">
      <div className="flex flex-col space-y-6 mb-12">
        <h2 className="text-xl font-bold mb-3 mt-8">Sales Information</h2>
        <div className="flex">
          <label htmlFor="fiatPrice" className="w-48">
            Price in Fiat (RM)
          </label>
          <div className="flex flex-col w-full">
            <input
              {...register(
                'fiatPrice',

                {
                  required:
                    profile?.merchantProfile?.paymentType === 'TOKEN'
                      ? null
                      : 'Fiat price cannot be empty',
                }
              )}
              defaultValue={state.formValues?.fiatPrice}
              type="number"
              className="block w-full h-full px-3 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
            />
            {errors.fiatPrice && (
              <p className="text-red-600 text-xs">{errors.fiatPrice.message}</p>
            )}
          </div>
        </div>
        <div className="flex">
          <label htmlFor="tokenPrice" className="w-48">
            Price in Token (AU)
          </label>
          <div className="flex flex-col w-full">
            <input
              {...register(
                'tokenPrice',

                {
                  required:
                    profile?.merchantProfile?.paymentType === 'FIAT'
                      ? null
                      : 'Token price cannot be empty',
                }
              )}
              defaultValue={state.formValues?.tokenPrice}
              type="number"
              className="block w-full h-full px-3 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
            />
            {errors.tokenPrice && (
              <p className="text-red-600 text-xs">
                {errors.tokenPrice.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex">
          <label htmlFor="stock" className="w-48">
            Stock
          </label>
          <div className="flex flex-col w-full">
            <input
              {...register('stock', {
                required: 'Stock cannot be empty',
              })}
              defaultValue={state.formValues?.stock}
              type="number"
              className="block w-full h-full px-3 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
            />
            {errors.stock && (
              <p className="text-red-600 text-xs">{errors.stock.message}</p>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <Button
          title="Previous"
          onClick={onPrevious}
          type="button"
          mode="outlined"
          loadingColor="primary"
        />
        <Button title="Next" type="submit" />
      </div>
    </form>
  );
};

export default CreateProductSalesInfoForm;
