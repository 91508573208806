import React from 'react';
import { useQuery } from 'react-query';
import Rating from 'react-rating';
import { useParams } from 'react-router';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { getTimeAgo } from '../../../app/helpers/datetime';
import { formatCurrency } from '../../../app/helpers/number';
import { fetchProductReviews } from '../../../app/repositories/product';
import ReviewCard from '../components/ReviewCard';

const ReviewsPage = () => {
  const { id } = useParams();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['products', id, 'reviews'],
    fetchProductReviews
  );

  const reviews = data?.data?.data?.reviews;

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col sm:rounded-lg rounded-none shadow-lg w-full max-w-full sm:max-w-5xl m-auto px-8 py-4 sm:px-20 sm:py-10 bg-white">
      <div className="flex-1">
        <div className="flex flex-col">
          <h2 className="text-2xl font-bold mb-3 mt-8">
            Product Reviews ({reviews?.length})
          </h2>
        </div>
        <div className="flex bg-gray-100 py-6 px-8 rounded">
          <div className="flex items-center space-x-4 overflow-scroll">
            <img
              key={reviews[0].product?.id}
              className="w-24 h-24 object-cover"
              src={
                reviews[0].product?.image ??
                reviews[0].product?.images[0]?.image
              }
              alt={`${reviews[0].product.id}`}
            />
            <div>
              <h3 className="font-semibold text-xl">
                {reviews[0].product?.name}
              </h3>
              <h5>{formatCurrency(reviews[0].product?.price)}</h5>
            </div>
          </div>
        </div>
        <div>
          {reviews?.map((review) => (
            <ReviewCard key={review.id} review={review} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewsPage;
