import React from 'react';
// import LatestTopups from '../components/LatestTopups';
import LatestOrders from '../components/LatestOrders';
import { fetchSales } from '../../../app/repositories/sales';
import { useQuery } from 'react-query';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import RecentlyCompletedOrders from '../components/RecentlyCompletedOrder';
import DataCard from '../../../app/components/common/DataCard';
import { fetchDashboardStats } from '../../../app/repositories/merchant';

const DashboardPage = () => {
  const {
    data: salesData,
    isLoading: salesIsLoading,
    isFetching: salesIsFetching,
  } = useQuery(['sales', { status: 'CONFIRMED', limit: 5 }], fetchSales);

  const {
    data: completedSalesData,
    isLoading: completedSalesIsLoading,
    isFetching: completedSalesIsFetching,
  } = useQuery(['sales', { status: 'COMPLETED', limit: 5 }], fetchSales);

  const {
    data: statsData,
    isLoading: statsIsLoading,
    isFetching: statsIsFetching,
  } = useQuery('dashboardStats', fetchDashboardStats);

  const stats = statsData?.data.data;

  if (salesIsFetching || completedSalesIsFetching || statsIsFetching) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col">
      <h2 className="text-2xl pb-6 font-semibold">Dashboard</h2>
      <div className="mb-6">
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <DataCard
            title="Total Incomes"
            content={stats.incomes?.toFixed(2) ?? 0}
          />
          <DataCard title="Total Orders" content={stats.sales ?? 0} />
          <DataCard
            title="Average Rating"
            content={stats.averageRating ?? 0.0}
          />
        </dl>
      </div>
      {/* <StatisticSummary data={dashboardData} /> */}
      <div className="flex flex-col space-y-6 space-x-0 md:flex-row md:space-x-6 md:space-y-0 overflow-scroll pb-6">
        <div className="flex-1">
          <LatestOrders data={salesData?.data?.data?.sales} />
        </div>
        <div className="flex-1">
          <RecentlyCompletedOrders
            data={completedSalesData?.data?.data?.sales}
          />
        </div>
        {/* <div className="flex-1">
          <LatestTopups data={topupHistory} />
        </div> */}
      </div>
    </div>
  );
};

export default DashboardPage;
