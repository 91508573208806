import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from '../../contexts/modalContext';
import { updateStock } from '../../../app/repositories/product';
import Button from '../common/Button';
import { ToastContext } from '../../contexts/toastContext';

export const UpdateStockModal = ({ product, refetch }) => {
  const { hideModal } = useContext(ModalContext);
  const [stock, setStock] = useState(product.stock);
  const { showToast } = useContext(ToastContext);

  const mutation = useMutation(() => updateStock(product.id, stock), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Update Product Stock',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast('SUCCESS', 'Success', 'Product stock updated successfully');
      refetch();
      hideModal();
    },
  });

  return (
    <div className="flex-1 flex flex-col justify-between px-4 py-3">
      <div>
        <div className="flex items-center space-x-3 mt-6">
          <img
            className="w-12 h-12 object-cover"
            src={product.images[0]}
            alt={product.name + product.id}
          />
          <div>
            <p className="text-lg font-semibold">{product.name}</p>
            <p>Current Stock: {product.stock}</p>
          </div>
        </div>
        <div className="flex my-6 items-center justify-between">
          <div className="flex-1">
            <label className="text-sm text-gray-500">New Stock</label>
            <input
              onChange={(e) => setStock(e.target.value)}
              value={stock}
              id="stock"
              type="number"
              className="block w-full h-full px-3 py-2 mt-1 text-gray-900 rounded-none placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm border border-gray-400"
              placeholder="Enter new stock"
            />
          </div>
        </div>
      </div>
      <Button
        title="Update Stock"
        block
        disabled={stock === ''}
        mode="secondary"
        isLoading={mutation.isLoading}
        loadingColor="dark"
        onClick={() => {
          mutation.mutate();
        }}
      />
    </div>
  );
};
