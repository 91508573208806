import React from 'react';
import { BeatLoader } from 'react-spinners';

const Button = ({
  onClick,
  disabled = false,
  isLoading,
  block = false,
  title,
  type = 'submit',
  loadingColor = 'light',
  mode = 'contained',
}) => {
  let _loadingColor;
  let _buttonClass;

  switch (loadingColor) {
    case 'primary':
      _loadingColor = '#3E74E4';
      break;
    case 'secondary':
      _loadingColor = '#F7C142';
      break;
    case 'light':
      _loadingColor = '#DDDDDD';
      break;

    case 'dark':
      _loadingColor = '#222222';
      break;

    default:
      break;
  }

  switch (mode) {
    case 'outlined':
      _buttonClass = `${
        block ? 'w-full' : 'w-40'
      } text-sm px-4 py-3 rounded-none leading-none border border-palette-primary text-palette-primary ${
        !(isLoading || disabled)
          ? 'hover:text-white hover:bg-palette-primary focus:outline-none cursor-pointer'
          : 'cursor-not-allowed'
      }`;
      break;

    case 'contained':
      _buttonClass = `${
        block ? 'w-full' : 'w-40'
      } text-sm px-4 py-3 rounded-none leading-none bg-palette-primary opacity-80 text-white ${
        !(isLoading || disabled)
          ? 'hover:text-white hover:opacity-100 focus:outline-none'
          : 'cursor-not-allowed'
      }`;
      break;

    case 'secondary':
      _buttonClass = `${
        block ? 'w-full' : 'w-40'
      } text-sm px-4 py-3 rounded-none leading-none bg-palette-secondary opacity-90 text-white ${
        !(isLoading || disabled)
          ? 'hover:opacity-100 focus:outline-none'
          : 'cursor-not-allowed'
      }`;
      break;

    case 'outlined-danger':
      _buttonClass = `${
        block ? 'w-full' : 'w-40'
      } text-sm px-4 py-3 leading-none border rounded-none text-red-600 border-red-600 ${
        !(isLoading || disabled)
          ? 'hover:border-transparent hover:text-white hover:bg-red-600'
          : 'cursor-not-allowed'
      }`;
      break;

    default:
      break;
  }

  return (
    <button
      type={type}
      disabled={isLoading || disabled}
      onClick={onClick}
      className={_buttonClass}
    >
      {isLoading ? <BeatLoader size={8} color={_loadingColor} /> : title}
    </button>
  );
};

export default Button;
