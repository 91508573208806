import { useStateMachine } from 'little-state-machine';
import React, { useContext, useEffect, useState } from 'react';
import CreateProductMainInfoForm from '../components/CreateProductMainInfoForm';
import CreateProductSalesInfoForm from '../components/CreateProductSalesInfoForm';
import CreateProductUploadPhotosForm from '../components/CreateProductUploadPhotosForm';
import updateForm from '../../../app/state/actions/updateForm';
import CreateProductPreviewForm from '../components/CreateProductPreviewForm';
import {
  createProduct,
  updateProduct,
} from '../../../app/repositories/product';
import { useMutation, useQueryClient } from 'react-query';
import CircleSteps from '../../../app/components/common/CircleSteps';
import { Link } from 'react-router-dom';
import history from '../../../app/helpers/history';
import clearForm from '../../../app/state/actions/clearForm';
import { ToastContext } from '../../../app/contexts/toastContext';

const ProductFormPage = ({ initStep = 1, isEdit = false }) => {
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [step, setStep] = useState(initStep);

  const { actions, state } = useStateMachine({ updateForm, clearForm });
  const createProductMutation = useMutation(
    (formData) => createProduct(formData),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Create New Product',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: (data) => {
        showToast('SUCCESS', 'Success', 'Product created successfully');
        queryClient.invalidateQueries('products');
        history.push('/products');
        actions.clearForm();
      },
    }
  );
  const editProductMutation = useMutation(
    (formData) => updateProduct(formData),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Update Product',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: (data) => {
        showToast('SUCCESS', 'Success', 'Product updated successfully');
        queryClient.invalidateQueries('products');
        history.push('/products');
        actions.clearForm();
      },
    }
  );

  const onSubmitForm = (formValues) => {
    actions.updateForm(formValues);
  };

  const nextStep = () => setStep(step + 1);

  const prevStep = () => setStep(step - 1);

  const onSaveProduct = (e) => {
    e.preventDefault();

    isEdit
      ? editProductMutation.mutate(state.formValues)
      : createProductMutation.mutate(state.formValues);
  };

  const renderForms = () => {
    switch (step) {
      case 1:
        return (
          <CreateProductMainInfoForm
            onSubmit={(formValues) => {
              onSubmitForm(formValues);
              nextStep();
            }}
          />
        );

      case 2:
        return (
          <CreateProductSalesInfoForm
            onSubmit={(formValues) => {
              onSubmitForm(formValues);
              nextStep();
            }}
            onPrevious={prevStep}
          />
        );

      case 3:
        return (
          <CreateProductUploadPhotosForm
            onSubmit={(formValues) => {
              onSubmitForm(formValues);
              nextStep();
            }}
            onPrevious={prevStep}
          />
        );

      case 4:
        return (
          <CreateProductPreviewForm
            onPrevious={prevStep}
            onSubmit={onSaveProduct}
            isSubmitting={
              isEdit
                ? editProductMutation.isLoading
                : createProductMutation.isLoading
            }
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col sm:rounded-lg rounded-none shadow-lg w-full max-w-full sm:max-w-5xl m-auto px-8 py-4 sm:px-20 sm:py-10 bg-white ">
      <div className="flex-1">
        <Link
          onClick={() => actions.clearForm()}
          className="text-palette-primary"
          to="/products"
        >
          ← Back to Dashboard
        </Link>
        <div className="flex justify-center mt-4">
          <CircleSteps steps={4} currentStep={step}>
            Steps
          </CircleSteps>
        </div>
        {renderForms()}
      </div>
    </div>
  );
};

export default ProductFormPage;
