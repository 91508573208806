import { Popover } from '@headlessui/react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import Button from '../../../app/components/common/Button';
import { AuthContext } from '../../../app/contexts/authContext';
import history from '../../../app/helpers/history';
import { login } from '../../../app/repositories/auth';
import logo from '../../../app/assets/images/aurachnid_logo.png';
import { Link } from 'react-router-dom';
import { ToastContext } from '../../../app/contexts/toastContext';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';

const LoginPage = () => {
  let { from } = history.location.state || { from: { pathname: '/' } };
  const { setToken } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mutation = useMutation(
    (formData) => login({ email: formData.email, password: formData.password }),
    {
      onSuccess: (result) => {
        setToken(result.data.token);
        history.replace(from);
        // SecureStore.setItemAsync('jwt-token', result.data.token);
        // history.goBack();
      },
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Authenticate',
          `${error.response?.data?.message || error.message}`
        );
      },
    }
  );

  const onSubmit = (formData) => {
    mutation.mutate(formData);
  };

  return (
    <div className="relative bg-gray-900 overflow-hidden min-h-screen">
      <div
        className="hidden sm:block sm:absolute sm:inset-0"
        aria-hidden="true"
      >
        <svg
          className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-palette-secondary lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width={364}
            height={384}
            fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
          />
        </svg>
      </div>
      <Popover className="relative pt-6 pb-16 sm:pb-24">
        {({ open }) => (
          <>
            <nav
              className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
              aria-label="Global"
            >
              <div className="flex items-center flex-1 justify-between">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="#">
                    <span className="sr-only">Aurachnid</span>
                    <img
                      className="h-12 w-auto sm:h-16"
                      src={logo}
                      alt="mudarabah logo"
                    />
                  </a>
                </div>
                <div>
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <MailIcon
                      className="flex-shrink-0 h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                    <a
                      href="mailto:support@aurachnid.com"
                      className="ml-3 text-white"
                    >
                      support@aurachnid.com
                    </a>
                  </dd>
                </div>
              </div>
            </nav>

            <main className="mt-12 sm:mt-28">
              <div className="mx-auto max-w-7xl">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                  <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                    <div>
                      <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                        <span className="md:block">Your marketplace for</span>{' '}
                        <span className="text-palette-primary md:block">
                          online business
                        </span>
                      </h1>
                      <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                        Grow your sales and bring your store to the next level!
                      </p>
                    </div>
                  </div>
                  <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                    <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                      <div className="px-4 py-8 sm:px-10">
                        <div className="mt-6">
                          <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="space-y-6"
                          >
                            <h3 className="text-lg leading-5 text-center">
                              Please Login to Continue
                            </h3>
                            <div>
                              <label htmlFor="email" className="sr-only">
                                Email
                              </label>
                              <input
                                {...register('email', {
                                  required: 'Email field cannot be empty',
                                })}
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="email"
                                placeholder="Email"
                                className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                              />
                              {errors.email && (
                                <p className="text-red-600 text-xs">
                                  {errors.email.message}
                                </p>
                              )}
                            </div>

                            <div>
                              <label htmlFor="password" className="sr-only">
                                Password
                              </label>
                              <input
                                {...register('password', {
                                  required: 'Password field cannot be empty',
                                })}
                                id="password"
                                name="password"
                                type="password"
                                placeholder="Password"
                                autoComplete="current-password"
                                className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                              />
                              {errors.password && (
                                <p className="text-red-600 text-xs">
                                  {errors.password.message}
                                </p>
                              )}
                            </div>

                            <Button
                              title="Login"
                              block
                              isLoading={mutation.isLoading}
                            />
                            <p className="text-xs leading-5 text-gray-500 text-center">
                              <Link
                                to="/forgot-password"
                                className="font-medium text-gray-900 hover:underline"
                              >
                                Forgot your password?
                              </Link>
                            </p>
                          </form>
                        </div>
                      </div>
                      <div className="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10">
                        <p className="text-xs leading-5 text-gray-500 text-center">
                          Don't have an account?{' '}
                          <Link
                            to="/signup"
                            className="font-medium text-gray-900 hover:underline"
                          >
                            Sign up here
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        )}
      </Popover>
    </div>
  );
};

export default LoginPage;
