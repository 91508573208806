import React, { useContext, useState } from 'react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { ModalContext } from '../../../app/contexts/modalContext';

const CompleteProfileBanner = ({ profile }) => {
  const checkProfileComplete = (profile) => {
    const {
      bankAccountName,
      bankAccountNumber,
      bankName,
      picName,
      idImage,
      idNumber,
    } = profile;

    return (
      !!bankAccountName &&
      !!bankAccountNumber &&
      !!bankName &&
      !!picName &&
      !!idImage &&
      !!idNumber
    );
  };

  const [bannerOpen, setBannerOpen] = useState(!checkProfileComplete(profile));
  const { showModal } = useContext(ModalContext);

  return bannerOpen ? (
    <div className="bg-palette-primary">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-blue-800">
              <ExclamationIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
            <p className="ml-3 font-medium text-white truncate">
              <span className="md:hidden">Complete your profile!</span>
              <span className="hidden md:inline">
                Please complete your profile to be able to release your payment.
              </span>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <button
              onClick={() =>
                showModal('Complete Profile', 'CompleteProfile', { profile })
              }
              className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-palette-primary bg-white hover:bg-indigo-50"
            >
              Complete Profile
            </button>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              onClick={() => setBannerOpen(false)}
              type="button"
              className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default CompleteProfileBanner;
