import { useContext } from 'react';
import { ModalContext } from '../../contexts/modalContext';
import { AddNewCategoryModal } from '../modals/AddNewCategoryModal';
import { AddToCartModal } from '../modals/AddToCartModal';
import { ArriveOrderModal } from '../modals/ArriveOrderModal';
import { ChangePasswordModal } from '../modals/ChangePasswordModal';
import { CompleteProfileModal } from '../modals/CompleteProfileModal';
import { DeactivateAccountModal } from '../modals/DeactivateAccountModal';
import { DeleteProductModal } from '../modals/DeleteProductModal';
import { DeliverOrderModal } from '../modals/DeliverOrderModal';
import { ShowCheckoutQrModal } from '../modals/ShowCheckoutQrModal';
import { UpdateStockModal } from '../modals/UpdateStockModal';

const ModalWrapper = () => {
  const { state, hideModal } = useContext(ModalContext);

  const renderComponent = (type) => {
    switch (type) {
      case 'AddToCart':
        return <AddToCartModal {...state.props} />;

      case 'ShowCheckoutQr':
        return <ShowCheckoutQrModal {...state.props} />;

      case 'AddNewCategory':
        return <AddNewCategoryModal {...state.props} />;

      case 'UpdateStock':
        return <UpdateStockModal {...state.props} />;

      case 'DeleteProduct':
        return <DeleteProductModal {...state.props} />;

      case 'ChangePassword':
        return <ChangePasswordModal {...state.props} />;

      case 'DeactivateAccount':
        return <DeactivateAccountModal {...state.props} />;

      case 'DeliverOrder':
        return <DeliverOrderModal {...state.props} />;

      case 'ArriveOrder':
        return <ArriveOrderModal {...state.props} />;

      case 'CompleteProfile':
        return <CompleteProfileModal {...state.props} />;

      // case 'AddNewUnit':
      //   return <AddNewUnitModal {...props} />;

      // case 'DeliverTransaction':
      //   return <DeliverTransactionModal {...props} />;

      // case 'CompleteTransaction':
      //   return <CompleteTransactionModal {...props} />;

      // case 'UpdateStock':
      //   return <UpdateStockModal {...props} />;

      default:
        return <div>Hello</div>;

      // return null;
    }
  };

  return state.show ? (
    <div className="fixed bottom-0 inset-x-0 sm:px-4 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center h-screen z-20">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
      </div>

      <div className="flex flex-col bg-white sm:rounded-lg px-4 pt-5 pb-4  shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6 max-h-screen h-full sm:h-auto overflow-auto">
        <div>
          <div className="flex justify-between">
            <div />
            <h3 className="text-xl font-semibold">{state.title}</h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 cursor-pointer"
              onClick={hideModal}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <hr className="-mx-4 sm:-mx-6 mt-3" />
        </div>
        {renderComponent(state.type)}
      </div>
    </div>
  ) : null;
};

export default ModalWrapper;
