/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CircleSteps = ({ steps, currentStep }) => {
  const stepsArray = Array.from(Array(steps + 1).keys()).slice(1);

  return (
    <nav aria-label="Product Create Progress">
      <ol className="flex items-center">
        {stepsArray.map((step, stepIdx) => (
          <li
            key={step}
            className={classNames(
              stepIdx !== stepsArray.length - 1 ? 'pr-8 sm:pr-20' : '',
              'relative'
            )}
          >
            {step < currentStep ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-palette-primary" />
                </div>
                <div className="relative w-8 h-8 flex items-center justify-center bg-palette-primary rounded-full hover:bg-blue-900">
                  <CheckIcon
                    className="w-5 h-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{`step ${step}`}</span>
                </div>
              </>
            ) : step === currentStep ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div
                  className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-palette-primary rounded-full"
                  aria-current="step"
                >
                  <span
                    className="h-2.5 w-2.5 bg-palette-primary rounded-full"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{`step ${step}`}</span>
                </div>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400">
                  <span
                    className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{`step ${step}`}</span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default CircleSteps;
