import { useStateMachine } from 'little-state-machine';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '../../../app/components/common/Button';

const SignupPersonalForm = ({ onSubmit }) => {
  const { state } = useStateMachine();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <main className="mt-4 sm:mt-12">
      <div className="mx-auto max-w-7xl">
        <div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
            <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
              <div className="px-4 py-8 sm:px-10">
                <div className="mt-6">
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div>
                      <h3 className="text-lg leading-5">Create Account</h3>
                      <p className="text-sm text-gray-400">
                        Please provide personal details for account access
                      </p>
                    </div>

                    <div>
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        {...register('email', {
                          required: 'Email field cannot be empty',
                        })}
                        defaultValue={state.signupFormValues?.email}
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        placeholder="Email"
                        className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                      />
                      {errors.email && (
                        <p className="text-red-600 text-xs">
                          {errors.email.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="contactNumber" className="sr-only">
                        Phone
                      </label>
                      <input
                        {...register('contactNumber', {
                          required: 'Phone number field cannot be empty',
                        })}
                        defaultValue={state.signupFormValues?.contactNumber}
                        type="phone"
                        name="contactNumber"
                        id="contactNumber"
                        autoComplete="phone"
                        placeholder="Phone"
                        className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                      />
                      {errors.contactNumber && (
                        <p className="text-red-600 text-xs">
                          {errors.contactNumber.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <input
                        {...register('password', {
                          required: 'Password field cannot be empty',
                          minLength: {
                            value: 8,
                            message:
                              'Password must contains at least 8 characters',
                          },
                        })}
                        defaultValue={state.signupFormValues?.password}
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                      />
                      {errors.password && (
                        <p className="text-red-600 text-xs">
                          {errors.password.message}
                        </p>
                      )}
                    </div>

                    <Button
                      title="Next"
                      block
                      // isLoading={mutation.isLoading}
                    />
                  </form>
                  <p className="text-xs pt-3 leading-5 text-gray-500 text-center">
                    By signing up, you agree to these{' '}
                    <Link
                      to="/login"
                      className="font-medium text-gray-900 hover:underline"
                    >
                      Terms & Condition
                    </Link>
                    {' and '}
                    <Link
                      to="/login"
                      className="font-medium text-gray-900 hover:underline"
                    >
                      Privacy Policies
                    </Link>
                  </p>
                </div>
              </div>
              <div className="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10">
                <p className="text-xs leading-5 text-gray-500 text-center">
                  Already have an account?{' '}
                  <Link
                    to="/login"
                    className="font-medium text-gray-900 hover:underline"
                  >
                    Login here
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignupPersonalForm;
