import React, { createContext, useEffect, useReducer } from 'react';

export const ModalContext = createContext({});

const initialState = {
  show: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ShowModal':
      return {
        show: true,
        title: action.payload.title,
        type: action.payload.type,
        props: action.payload.props,
      };
    case 'HideModal':
      return {
        show: false,
      };
    default:
      return state;
  }
};

export const ModalContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showModal = (title, type, props) => {
    dispatch({
      type: 'ShowModal',
      payload: {
        title,
        type,
        props,
      },
    });
  };

  const hideModal = () => {
    dispatch({ type: 'HideModal' });
  };

  return (
    <ModalContext.Provider value={{ state, hideModal, showModal }}>
      {props.children}
    </ModalContext.Provider>
  );
};
