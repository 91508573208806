import { useStateMachine } from 'little-state-machine';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import Button from '../../../app/components/common/Button';
import {
  createProduct,
  fetchProductCategories,
} from '../../../app/repositories/product';
import clearForm from '../../../app/state/actions/clearForm';
import history from '../../../app/helpers/history';

const CreateProductPreviewForm = ({ onPrevious, onSubmit, isSubmitting }) => {
  const { state, actions } = useStateMachine({ clearForm });

  const { data, isLoading, isFetching, refetch } = useQuery(
    'categories',
    fetchProductCategories
  );

  const thumbs = state?.formValues?.images?.map((file) => (
    <div
      key={file.preview}
      className="w-40 h-40 border overflow-hidden relative"
    >
      <img
        className="absolute inset-0 m-auto"
        src={file.preview}
        alt={`${file.name}`}
      />
    </div>
  ));

  return (
    <form onSubmit={onSubmit} className="flex-1">
      <div className="flex flex-col items-center">
        <h2 className="text-xl font-bold mb-3 mt-8">Product Preview</h2>
      </div>
      <div className="flex flex-col space-y-6 mb-8">
        <h2 className="text-xl font-bold mb-3 mt-8">Product Information</h2>
        <div className="flex">
          <h5 className="w-48">Product Name</h5>
          <input
            value={state?.formValues?.name}
            readOnly
            className="block w-full h-full pl-3 pr-10 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
          />
        </div>
        <div className="flex">
          <h5 className="w-48">Description</h5>
          <textarea
            value={state?.formValues.description}
            readOnly
            rows={8}
            className="block w-full h-full pl-3 pr-10 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
          />
        </div>
        <div className="flex">
          <h5 className="w-48">Category</h5>
          <div className="flex space-x-2 w-full">
            <div className="h-20 px-6 items-center justify-center flex rounded-md bg-palette-primary text-white shadow-md">
              <p>
                {
                  data?.data?.data?.categories.find(
                    (e) => e.id == state.formValues.categoryId
                  ).name
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-6 mb-8">
        <h2 className="text-xl font-bold mb-3 mt-8">Sales Information</h2>
        <div className="flex">
          <h5 className="w-48">Price in Fiat (RM)</h5>
          <input
            value={state?.formValues?.fiatPrice}
            readOnly
            className="block w-full h-full pl-3 pr-10 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
          />
        </div>
        <div className="flex">
          <h5 className="w-48">Price in Token (AU)</h5>
          <input
            value={state?.formValues?.tokenPrice}
            readOnly
            className="block w-full h-full pl-3 pr-10 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
          />
        </div>
        <div className="flex">
          <h5 className="w-48">Stock</h5>
          <input
            value={state?.formValues?.stock}
            readOnly
            className="block w-full h-full pl-3 pr-10 py-2 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
          />
        </div>
      </div>
      <div className="flex flex-col space-y-6 mb-8">
        <h2 className="text-xl font-bold mb-3 mt-8">Product Photos</h2>
        <div className="flex items-start space-x-4">{thumbs}</div>
      </div>
      <div className="flex justify-between">
        <Button
          title="Previous"
          onClick={onPrevious}
          type="button"
          mode="outlined"
          loadingColor="primary"
        />

        <Button title="Save" type="submit" isLoading={isSubmitting} />
      </div>
    </form>
  );
};

export default CreateProductPreviewForm;
