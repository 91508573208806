import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';

export const ProtectedRoute = ({ children, ...rest }) => {
  const { state } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        state.isLoggedIn ? (
          React.cloneElement(children, { ...rest })
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location.pathname },
            }}
          />
        )
      }
    />
  );
};
