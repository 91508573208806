import React from 'react';
import Loader from 'react-spinners/GridLoader';

export const LoadingIndicator = ({ relative }) => {
  return (
    <div
      className={`${
        relative ? 'relative' : 'absolute'
      } m-auto inset-0 flex items-center justify-center`}
    >
      <Loader color="#1FD9A1" />
    </div>
  );
};
