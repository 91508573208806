import React from 'react';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { formatCurrency, formatNumber } from '../../../app/helpers/number';

const SalesTable = ({ sales, fetchNextPage, hasNextPage }) => {
  const renderRow = (sales) => {
    return (
      <>
        {sales.length === 0 ? (
          <tr>
            <td className="text-center p-3" colSpan={5}>
              <p>No data available</p>
            </td>
          </tr>
        ) : (
          sales.map((sale, index) => (
            <tr key={sale.id}>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 font-semibold">
                <div>
                  <h5 className="text-palette-secondary font-semibold">
                    {sale.status}
                  </h5>
                  <h3 className="text-palette-primary font-semibold text-xl my-2">
                    {sale.user.name.split(' ')[0]}
                  </h3>
                  <p className="text-xs text-gray-400">ORD{sale.id}</p>
                </div>
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4">
                {sale.deliveryAddress ? (
                  <div>
                    <p className="pb-4">
                      {sale.deliveryAddress?.recipientName} (
                      {sale.deliveryAddress?.recipientNumber})
                    </p>
                    <p>
                      {sale.deliveryAddress?.recipientAddress},{' '}
                      {sale.deliveryAddress?.recipientPostalCode}
                    </p>
                  </div>
                ) : (
                  <p>Oflline Checkout</p>
                )}
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4">
                <p>{sale.items?.length} products</p>
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4">
                <h5 className="text-lg font-semibold text-palette-secondary">
                  {sale.paymentType === 'TOKEN' ? 'AU' : 'RM'}{' '}
                  {formatNumber(sale.amount)}
                </h5>
              </td>
              <td className="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4">
                <Link
                  to={`sales/${sale.id}`}
                  className="text-base font-semibold text-palette-primary cursor-pointer"
                >
                  Details
                </Link>
                <Waypoint
                  onEnter={() => {
                    if ((index + 1) % 10 === 0 && hasNextPage) {
                      fetchNextPage();
                    }
                  }}
                />
              </td>
            </tr>
          ))
        )}
      </>
    );
  };

  return (
    <div className="min-w-full bg-white py-4 shadow-md">
      <div className="py-2 overflow-auto min-w-full">
        <div className="align-middle inline-block min-w-full border-b border-gray-200 overflow-auto max-h-2/3-screen">
          {/* <div className="align-middle inline-block min-w-full border-b border-gray-200 overflow-auto max-h-72"> */}
          <table className="min-w-full relative">
            <thead>
              <tr>
                <th className="w-72 sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Customer
                </th>
                <th className="w-72 sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Address
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Order
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Payment
                </th>
                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-palette-primary text-white text-left text-xs leading-4 font-medium uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>{renderRow(sales)}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SalesTable;
