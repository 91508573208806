import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { ModalContext } from '../../../app/contexts/modalContext';
import { formatCurrency, formatNumber } from '../../../app/helpers/number';
import { fetchSaleById } from '../../../app/repositories/sales';

const SaleDetailsPage = () => {
  const { id } = useParams();
  const { showModal } = useContext(ModalContext);

  const { data, isLoading, isFetching, refetch } = useQuery(['sales', id], () =>
    fetchSaleById(id)
  );

  const sale = data?.data?.data?.sale;

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col bg-white shadow-md px-8 py-6">
      <div className="mb-4">
        <h4 className="text-2xl font-bold">ORD{sale?.id}</h4>
        <h4 className="text-xl font-bold text-palette-secondary">
          {sale?.status}
        </h4>
      </div>
      <div className="flex flex-col lg:flex-row lg:space-x-6 space-y-4 lg:space-y-0">
        <div className="flex-1">
          <h3 className="text-xl mb-2 text-palette-primary font-semibold">
            Product Information
          </h3>
          {sale.items?.map((item) => {
            return (
              <div
                key={item.product.id}
                className="flex lg:w-96 items-center justify-between bg-gray-100 py-3 px-5 rounded my-2"
              >
                <div className="flex">
                  <img
                    alt={item.product.images[0]}
                    src={item.product.images[0]}
                    className="h-12 mr-3"
                  />
                  <div className="flex flex-col">
                    <h4>{item.product.name}</h4>
                    <h4>
                      {sale.paymentType === 'TOKEN' ? 'AU' : 'RM'}{' '}
                      {item?.product?.productPrice
                        ? formatNumber(
                            sale.paymentType === 'TOKEN'
                              ? item?.product?.productPrice?.tokenPrice
                              : item?.product?.productPrice?.fiatPrice
                          )
                        : formatNumber(item?.product?.price)}
                    </h4>
                    <h4 className="text-sm">{item.product.note}</h4>
                  </div>
                </div>

                <h4 className="text-lg font-semibold">x{item.quantity}</h4>
              </div>
            );
          })}
        </div>
        <div className="md:w-72">
          {sale.deliveryAddress ? (
            <div className="mb-4">
              <h3 className="text-xl mb-2  text-palette-primary font-semibold">
                Delivery Information
              </h3>
              <h4>{sale.deliveryAddress.recipientName}</h4>
              <h4>{sale.deliveryAddress.recipientNumber}</h4>
              <h4>{sale.deliveryAddress.recipientAddress}</h4>
            </div>
          ) : null}
        </div>

        <div className="lg:w-72">
          <h3 className="text-xl mb-2  text-palette-primary font-semibold">
            Payment Information
          </h3>
          {/* <div className="flex justify-between">
            <h4>Subtotal of Products</h4>
            <h4 className="text-palette-secondary">
              RM{(sale.amount / 100).toFixed(2)}
            </h4>
          </div>
          <div className="flex justify-between">
            <h4>VAT 6%</h4>
            <h4 className="text-palette-secondary">
              RM{((sale.amount * 6) / 100).toFixed(2)}
            </h4>
          </div>
          <div className="flex justify-between">
            <h4>Delivery Fee</h4>
            <h4 className="text-palette-secondary">RM5.0</h4>
          </div> */}
          {/* <hr /> */}
          <div className="flex justify-between font-semibold">
            <h4>Total Payment</h4>
            <h4 className="text-palette-secondary">
              {sale.paymentType === 'TOKEN' ? 'AU' : 'RM'}{' '}
              {formatNumber(sale.amount)}
            </h4>
          </div>
        </div>
      </div>
      {sale?.status === 'CONFIRMED' ? (
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() =>
              showModal('Deliver Order', 'DeliverOrder', {
                id: sale?.id,
              })
            }
            className="mt-20 text-sm px-4 py-3 rounded-none leading-none bg-palette-primary opacity-80 text-white hover:text-white hover:opacity-100 focus:outline-none"
          >
            Deliver Order
          </button>
        </div>
      ) : null}
      {sale?.status === 'DELIVERING' ? (
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() =>
              showModal('Complete Transaction', 'ArriveOrder', {
                id: sale?.id,
              })
            }
            className="mt-20 text-sm px-4 py-3 rounded-none leading-none bg-palette-primary opacity-80 text-white hover:text-white hover:opacity-100 focus:outline-none"
          >
            Complete Transaction
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default SaleDetailsPage;
