import React from 'react';
import Rating from 'react-rating';
import { getTimeAgo } from '../../../app/helpers/datetime';

const ReviewCard = ({ review }) => {
  return (
    <div
      key={review.id}
      className="flex items-center w-full border border-gray-200 px-8 py-6 space-x-4"
    >
      <img
        src={review?.user.avatar}
        alt={`${review?.user.name} avatar`}
        className="w-16 h-16 rounded-full object-cover"
      />
      <div className="flex flex-col">
        <h5>
          {review?.user.name} •{' '}
          <span className="text-gray-400 text-sm">
            {getTimeAgo(review?.createdAt)}
          </span>
        </h5>

        <Rating
          initialRating={review?.stars ?? 0}
          readonly
          emptySymbol={<i className="fa fa-star-o text-palette-secondary" />}
          fullSymbol={<i className="fa fa-star text-palette-secondary" />}
        />
        <h5>{review?.feedback}</h5>
      </div>
    </div>
  );
};

export default ReviewCard;
