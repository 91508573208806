import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createStore, StateMachineProvider } from 'little-state-machine';
import { AuthContextProvider } from './app/contexts/authContext';
import { ModalContextProvider } from './app/contexts/modalContext';
import { ToastContextProvider } from './app/contexts/toastContext';

const queryClient = new QueryClient();

createStore({});

ReactDOM.render(
  <AuthContextProvider>
    <ToastContextProvider>
      <ModalContextProvider>
        <StateMachineProvider>
          <QueryClientProvider client={queryClient}>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </QueryClientProvider>
        </StateMachineProvider>
      </ModalContextProvider>
    </ToastContextProvider>
  </AuthContextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
