import { api } from '../api/api';

export const login = ({ email, password }) => {
  return api.post(
    '/auth/login',
    { email, password },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
};

export const forgotPassword = ({ email }) => {
  return api.post(
    '/auth/forgot-password',
    { email },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const resetPassword = ({ token, password }) => {
  return api.post(
    '/auth/reset-password',
    { token, password },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const updatePassword = ({
  currentPassword,
  password,
  passwordConfirm,
}) => {
  const accessToken = localStorage.getItem('mudarabah-token');

  return api.patch(
    '/auth/update-password',
    { currentPassword, password, passwordConfirm },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

// /signup/merchant
export const signupMerchant = (formValues) => {
  const {
    email,
    name,
    contactNumber,
    password,
    country,
    address,
    picName,
    idNumber,
    idImage,
    bankName,
    bankAccountName,
    bankAccountNumber,
    merchantImage,
    paymentType,
  } = formValues;

  const formData = new FormData();
  email && formData.append('email', email);
  name && formData.append('name', name);
  contactNumber && formData.append('contactNumber', contactNumber);
  password && formData.append('password', password);
  country && formData.append('country', country);
  address && formData.append('address', address);
  picName && formData.append('picName', picName);
  idNumber && formData.append('idNumber', idNumber);
  paymentType && formData.append('paymentType', paymentType);
  idImage && formData.append('idImage', idImage);
  bankName && formData.append('bankName', bankName);
  bankAccountName && formData.append('bankAccountName', bankAccountName);
  bankAccountNumber && formData.append('bankAccountNumber', bankAccountNumber);
  merchantImage && formData.append('merchantImage', merchantImage);

  return api.post('auth/signup/merchant', formData, {
    headers: { enctype: 'multipart/form-data' },
  });
};
