import { Popover } from '@headlessui/react';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import logo from '../../../app/assets/images/aurachnid_logo.png';
import Button from '../../../app/components/common/Button';
import { ToastContext } from '../../../app/contexts/toastContext';
import history from '../../../app/helpers/history';
import { forgotPassword } from '../../../app/repositories/auth';

const ForgotPasswordPage = () => {
  const { showToast } = useContext(ToastContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (formValues) => {
    mutation.mutate(formValues);
  };

  const mutation = useMutation((formData) => forgotPassword(formData), {
    onSuccess: (result) => {
      showToast(
        'SUCCESS',
        'Success',
        'Password reset instruction sent successfully'
      );

      history.replace('/reset-password');
    },
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Send Forgot Password Email',
        `${error.response?.data?.message || error.message}`
      );
    },
  });

  return (
    <div className="relative bg-gray-900 overflow-hidden min-h-screen">
      <div
        className="hidden sm:block sm:absolute sm:inset-0"
        aria-hidden="true"
      >
        <svg
          className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-palette-secondary lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width={364}
            height={384}
            fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
          />
        </svg>
      </div>
      <Popover className="relative pt-6 pb-16 sm:pb-24">
        {({ open }) => (
          <>
            <nav
              className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
              aria-label="Global"
            >
              <div className="flex items-center flex-1">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="#">
                    <span className="sr-only">Mudarabah</span>
                    <img
                      className="h-12 w-auto sm:h-16"
                      src={logo}
                      alt="mudarabah logo"
                    />
                  </a>
                </div>
              </div>
            </nav>

            <main className="mt-4 sm:mt-12">
              <div className="mx-auto max-w-7xl">
                <div>
                  <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                    <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                      <div className="px-4 py-8 sm:px-10">
                        <div className="mt-6">
                          <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="space-y-6"
                          >
                            <div>
                              <h3 className="text-lg leading-5">
                                Forgot Password
                              </h3>
                              <p className="text-sm text-gray-400">
                                Please enter your email and we will send you
                                password reset instruction
                              </p>
                            </div>

                            <div>
                              <label htmlFor="email" className="sr-only">
                                Email
                              </label>
                              <input
                                {...register('email', {
                                  required: 'Email field cannot be empty',
                                })}
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="email"
                                placeholder="Email"
                                className="block w-full h-full p-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm bg-gray-300"
                              />
                              {errors.email && (
                                <p className="text-red-600 text-xs">
                                  {errors.email.message}
                                </p>
                              )}
                            </div>

                            <Button
                              title="Send"
                              block
                              isLoading={mutation.isLoading}
                            />
                          </form>
                        </div>
                      </div>
                      <div className="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10">
                        <p className="text-xs leading-5 text-gray-500 text-center">
                          Remember your password?{' '}
                          <Link
                            to="/login"
                            className="font-medium text-gray-900 hover:underline"
                          >
                            Login here
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ForgotPasswordPage;
