import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { formatCurrency } from '../../../app/helpers/number';
import { fetchProductDiscussions } from '../../../app/repositories/product';
import DiscussionCard from '../components/DiscussionCard';

const DiscussionsPage = () => {
  const { id } = useParams();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['products', id, 'discussions'],
    fetchProductDiscussions
  );

  const discussions = data?.data?.data?.discussions;

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col sm:rounded-lg rounded-none shadow-lg w-full max-w-full sm:max-w-5xl m-auto px-8 py-4 sm:px-20 sm:py-10 bg-white">
      <div className="flex-1">
        <div className="flex flex-col">
          <h2 className="text-2xl font-bold mb-3 mt-8">
            Product Discussions ({discussions?.length})
          </h2>
        </div>
        <div className="flex py-6 rounded">
          <div className="flex items-center space-x-4 overflow-scroll">
            <img
              key={discussions[0].product?.id}
              className="w-24 h-24 object-cover"
              src={discussions[0].product?.images[0]}
              alt={`${discussions[0].product?.id}`}
            />
            <div>
              <h3 className="font-semibold text-xl">
                {discussions[0].product?.name}
              </h3>
              <h5>{formatCurrency(discussions[0].product?.price)}</h5>
            </div>
          </div>
        </div>
        <div>
          {discussions?.map((discussion) => (
            <DiscussionCard key={discussion.id} discussion={discussion} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DiscussionsPage;
