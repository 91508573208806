import { Transition } from '@headlessui/react';
import React, { useContext, useEffect } from 'react';
import { ToastContext } from '../../contexts/toastContext';

export const Toast = () => {
  const { state, hideToast } = useContext(ToastContext);

  const typeClass = (type) => {
    switch (type) {
      case 'SUCCESS':
        return 'bg-green-700';
      case 'ERROR':
        return 'bg-red-700';
      case 'WARNING':
        return 'bg-yellow-700';
      default:
        return 'bg-blue-700';
    }
  };

  return (
    <Transition
      show={state.show}
      className={`max-w-sm w-full ${typeClass(
        state.type
      )} shadow-lg pointer-events-auto z-10`}
    >
      <Transition.Child
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <h6 className="text-base text-white font-semibold">
                {state.title}
              </h6>
              <p className="mt-1 text-sm leading-5 text-white">{state.body}</p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                onClick={hideToast}
                className="inline-flex text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
              >
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </Transition.Child>
      {/* <div
          onClick={() => setSidebarOpen(false)}
          className={`fixed inset-0 z-30 bg-gray-600 opacity-0 pointer-events-none transition-opacity ease-linear duration-300 ${
            sidebarOpen
              ? "opacity-75 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        /> */}

      <Transition.Child
        enter="ease-linear duration-5000 transition-width"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2 w-full"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0 w-0"
      >
        <div className="h-1 bg-white" />
      </Transition.Child>
    </Transition>
  );
};
