import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from '../../contexts/modalContext';
import { ToastContext } from '../../contexts/toastContext';
import { updatePassword } from '../../repositories/auth';
import Button from '../common/Button';

export const ChangePasswordModal = () => {
  const { showToast } = useContext(ToastContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const { hideModal } = useContext(ModalContext);
  const mutation = useMutation(
    (fv) =>
      updatePassword({
        currentPassword: fv.currentPassword,
        password: fv.password,
        passwordConfirm: fv.passwordConfirm,
      }),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Update password',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: () => {
        showToast('SUCCESS', 'Success', 'Password updated successfully');
        hideModal();
      },
    }
  );

  const onSubmit = (formValues) => {
    mutation.mutate(formValues);
  };

  return (
    <form
      className="flex-1 flex flex-col justify-between px-4 py-3 space-y-3"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <input
          {...register('currentPassword', {
            required: 'Current password cannot be empty',
          })}
          id="currentPassword"
          type="password"
          className="block w-full h-full px-3 py-2 text-gray-900 rounded-none placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm border border-gray-400"
          placeholder="Enter current password"
        />
        {errors.currentPassword && (
          <p className="text-red-600 text-xs">
            {errors.currentPassword.message}
          </p>
        )}
      </div>
      <div>
        <input
          {...register('password', {
            required: 'New password cannot be empty',
          })}
          id="password"
          type="password"
          className="block w-full h-full px-3 py-2 text-gray-900 rounded-none placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm border border-gray-400"
          placeholder="Enter new password"
        />
        {errors.password && (
          <p className="text-red-600 text-xs">{errors.password.message}</p>
        )}
      </div>
      <div>
        <input
          {...register('passwordConfirm', {
            validate: (value) => value === watch('password'),
          })}
          id="passwordConfirm"
          type="password"
          className="block w-full h-full px-3 py-2 text-gray-900 rounded-none placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm border border-gray-400"
          placeholder="Re-type your new password"
        />
        {errors?.passwordConfirm?.type === 'validate' && (
          <p className="text-red-600 text-xs">
            Password and confirm password does not match
          </p>
        )}
      </div>
      <Button
        title="Save"
        block
        mode="secondary"
        isLoading={mutation.isLoading}
        loadingColor="dark"
      />
    </form>
  );
};
