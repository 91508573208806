import { Redirect, Route, Router, Switch } from 'react-router';
import DashboardLayout from './layout/DashboardLayout';
import history from './helpers/history';
import ProductPage from '../features/products/pages/ProductPage';
import SalesPage from '../features/sales/pages/SalesPage';
import NavbarLayout from './layout/NavbarLayout';
import ProductDetailsPage from '../features/products/pages/ProductDetailsPage';
import SaleDetailsPage from '../features/sales/pages/SaleDetailsPage';
import DashboardPage from '../features/dashboard/pages/DashboardPage';
import DiscussionsPage from '../features/discussions/pages/DiscussionsPage';
import ReviewsPage from '../features/reviews/pages/ReviewsPage';
import OfflineTransactionPage from '../features/offline-store/pages/OfflineTransactionPage';
import ModalWrapper from './components/common/ModalWrapper';
import ProductFormPage from '../features/products/pages/ProductFormPage';
import ProfileDetailsPage from '../features/profile/pages/ProfileDetailsPage';
import LoginPage from '../features/auth/pages/LoginPage';
import { useContext } from 'react';
import { AuthContext } from './contexts/authContext';
import { ProtectedRoute } from './components/common/ProtectedRoute';
import SignupPage from '../features/auth/pages/SignupPage';
import ForgotPasswordPage from '../features/auth/pages/ForgotPasswordPage';
import ResetPasswordPage from '../features/auth/pages/ResetPasswordPage';
import { Toast } from './components/common/Toast';

function App() {
  let { from } = history.location.state || { from: { pathname: '/' } };
  const { state } = useContext(AuthContext);

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" exact>
          {state.isLoggedIn ? <Redirect to={from} /> : <LoginPage />}
        </Route>
        <Route path="/signup" exact>
          {state.isLoggedIn ? <Redirect to={from} /> : <SignupPage />}
        </Route>
        <Route path="/forgot-password" exact>
          <ForgotPasswordPage />
        </Route>
        <Route path="/reset-password" exact>
          <ResetPasswordPage />
        </Route>
        <ProtectedRoute path="/" exact>
          <Redirect to="/dashboard" />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard" exact>
          <DashboardLayout>
            <DashboardPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/products" exact>
          <DashboardLayout title="Products">
            <ProductPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/products/new" exact>
          <NavbarLayout>
            <ProductFormPage />
          </NavbarLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/products/:id" exact>
          <DashboardLayout>
            <ProductDetailsPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/products/:id/edit" exact>
          <DashboardLayout>
            <ProductFormPage isEdit />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/products/:id/discussions" exact>
          <DashboardLayout>
            <DiscussionsPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/products/:id/reviews" exact>
          <DashboardLayout>
            <ReviewsPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/sales" exact>
          <DashboardLayout title="Sales">
            <SalesPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/sales/:id" exact>
          <DashboardLayout>
            <SaleDetailsPage />
          </DashboardLayout>
        </ProtectedRoute>
        {/* <ProtectedRoute path="/token" exact>
          <DashboardLayout title="Token">
            <div>Hello Token</div>
          </DashboardLayout>
        </ProtectedRoute> */}
        {/* <ProtectedRoute path="/offline-store" exact>
          <DashboardLayout>
            <OfflineTransactionPage />
          </DashboardLayout>
        </ProtectedRoute> */}
        <ProtectedRoute path="/profile" exact>
          <DashboardLayout>
            <ProfileDetailsPage />
          </DashboardLayout>
        </ProtectedRoute>
      </Switch>
      <div className="fixed z-10 items-center sm:items-end pointer-events-none justify-end sm:justify-start inset-0 my-6 mx-4 space-y-3 flex flex-col">
        <Toast />
      </div>
      <ModalWrapper />
    </Router>
  );
}

export default App;
