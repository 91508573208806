import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import Button from '../../../app/components/common/Button';
import { ToastContext } from '../../../app/contexts/toastContext';
import { formatFullDate } from '../../../app/helpers/datetime';
import { replyDiscussion } from '../../../app/repositories/product';

const DiscussionCard = ({ discussion }) => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const mutation = useMutation(
    (message) => replyDiscussion(discussion.product.id, discussion.id, message),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Post Message',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: (data) => {
        showToast(
          'SUCCESS',
          'Success',
          'Discussion message posted successfully'
        );
        queryClient.invalidateQueries('products');

        // history.goBack();
      },
      onSettled: () => {
        reset();
      },
    }
  );

  const onSendReply = (formData) => {
    mutation.mutate(formData.message);
  };

  return (
    <div className="mb-6">
      <div className="flex w-full border border-gray-200 px-8 py-6 items-center space-x-4">
        <img
          src={discussion.user.avatar}
          alt={`${discussion.user.name} avatar`}
          className="w-12 h-12 rounded-full object-cover"
        />
        <div>
          <h5>
            {discussion?.user.name} •{' '}
            <span className="text-gray-400 text-sm">
              {formatFullDate(discussion?.createdAt)}
            </span>
          </h5>
          <h5>{discussion?.message}</h5>
        </div>
      </div>
      {discussion?.replies.map((reply) => (
        <div
          key={reply.id}
          className="flex w-full border border-t-0 bg-gray-50 border-gray-200 px-20 py-6 items-center space-x-4"
        >
          <img
            src={reply.user.avatar}
            alt={`${reply.user.name} avatar`}
            className="w-12 h-12 rounded-full object-cover"
          />
          <div>
            <h5>
              {reply?.user.name} •{' '}
              <span className="text-gray-400 text-sm">
                {formatFullDate(reply?.createdAt)}
              </span>
            </h5>
            <h5>{reply?.message}</h5>
          </div>
        </div>
      ))}
      <form onSubmit={handleSubmit(onSendReply)} className="flex">
        <input
          {...register('message', {
            required: "Message can't be empty",
          })}
          type="text"
          className="block w-full h-full px-5 border border-t-0 py-3 rounded-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400"
        />
        <Button isLoading={mutation.isLoading} type="submit" title="Send" />
      </form>
      {errors.message && (
        <p className="text-red-600 text-xs">{errors.message.message}</p>
      )}
    </div>
  );
};

export default DiscussionCard;
