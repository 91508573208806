import { Popover } from '@headlessui/react';
import { useStateMachine } from 'little-state-machine';
import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import logo from '../../../app/assets/images/aurachnid_logo.png';
import Button from '../../../app/components/common/Button';
import { AuthContext } from '../../../app/contexts/authContext';
import { ToastContext } from '../../../app/contexts/toastContext';
import history from '../../../app/helpers/history';
import { signupMerchant } from '../../../app/repositories/auth';
import clearSignupForm from '../../../app/state/actions/clearForm';
import updateSignupForm from '../../../app/state/actions/updateSignupForm';
import SignupBankInfoForm from '../components/SignupBankInfoForm';
import SignupMerchantForm from '../components/SignupMerchantForm';
import SignupPersonalForm from '../components/SignupPersonalForm';
import SignupVerificationForm from '../components/SignupVerificationForm';

const SignupPage = () => {
  const [step, setStep] = useState(1);
  const { actions, state } = useStateMachine({
    updateSignupForm,
    clearSignupForm,
  });
  const { setToken } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);

  const mutation = useMutation((formData) => signupMerchant(formData), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Signup',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: (result) => {
      // queryClient.invalidateQueries('products');
      setToken(result.data.token);
      history.push('/');
      actions.clearSignupForm();
    },
  });

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const onSubmitForm = (formValues) => {
    actions.updateSignupForm(formValues);
  };

  const onSignupSubmit = () => {
    console.log(state.signupFormValues);
    mutation.mutate(state.signupFormValues);
  };

  const renderSignupForms = () => {
    switch (step) {
      case 1:
        return (
          <SignupPersonalForm
            onSubmit={(formValues) => {
              onSubmitForm(formValues);
              nextStep();
            }}
          />
        );

      case 2:
        return (
          <SignupMerchantForm
            onSubmit={(formValues) => {
              console.log(formValues);
              onSubmitForm(formValues);
              nextStep();
            }}
            onPrevious={prevStep}
          />
        );

      case 3:
        return (
          <SignupVerificationForm
            onSubmit={(formValues) => {
              onSubmitForm(formValues);
              nextStep();
            }}
            onPrevious={prevStep}
            onSkip={nextStep}
          />
        );

      case 4:
        return (
          <SignupBankInfoForm
            onSubmit={(formValues) => {
              onSubmitForm(formValues);
              onSignupSubmit();
            }}
            isSubmitting={mutation.isLoading}
            onPrevious={prevStep}
            onSkip={onSignupSubmit}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="relative bg-gray-900 overflow-hidden min-h-screen">
      <div
        className="hidden sm:block sm:absolute sm:inset-0"
        aria-hidden="true"
      >
        <svg
          className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-palette-secondary lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width={364}
            height={384}
            fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
          />
        </svg>
      </div>
      <Popover className="relative pt-6 pb-16 sm:pb-24">
        {({ open }) => (
          <>
            <nav
              className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
              aria-label="Global"
            >
              <div className="flex items-center flex-1">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="#">
                    <span className="sr-only">Mudarabah</span>
                    <img
                      className="h-12 w-auto sm:h-16"
                      src={logo}
                      alt="mudarabah logo"
                    />
                  </a>
                </div>
              </div>
            </nav>

            {renderSignupForms()}
          </>
        )}
      </Popover>
    </div>
  );
};

export default SignupPage;
