import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import ProductSearchBox from '../components/ProductSearchBox';
import ProductTable from '../components/ProductTable';
import { sortByDate, sortById } from '../../../app/helpers/sortHelper';
import { fetchProducts } from '../../../app/repositories/product';
import { useInfiniteQuery, useQuery } from 'react-query';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';

const ProductPage = () => {
  let products = [];
  const [searchQuery, setSearchQuery] = useState('');
  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(['products', { search: searchQuery }], fetchProducts, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.data.products.length === 10) {
        return pages.length + 1;
      } else {
        return undefined;
      }
    },
  });

  const productPages = data?.pages.map((page) => page?.data?.data?.products);
  productPages?.forEach((i) => products.push(...i));

  return (
    <div className="flex flex-col bg-white shadow-md">
      <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 justify-between sm:items-center px-8 pt-6 pb-2">
        <ProductSearchBox
          onSearch={(query) => {
            setSearchQuery(query);
          }}
        />
        <Link
          to="/products/new"
          className="text-center w-full sm:w-auto text-sm px-4 py-2 rounded-none leading-none bg-palette-primary opacity-80 text-white hover:text-white hover:opacity-100 focus:outline-none"
        >
          Add New Product
        </Link>
      </div>

      {isLoading ? (
        <div className="py-60">
          <LoadingIndicator relative />
        </div>
      ) : (
        <ProductTable
          products={products}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      )}
      {isFetchingNextPage ? (
        <div className="relative -mt-10 bg-palette-primary opacity-80 text-white text-center py-2">
          Loading more data...
        </div>
      ) : null}
    </div>
  );
};

export default ProductPage;
