import { useStateMachine } from 'little-state-machine';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '../../../app/components/common/Button';
import updateForm from '../../../app/state/actions/updateForm';
import ProductFileUpload from './ProductFileUpload';

const CreateProductUploadPhotosForm = ({ onSubmit, onPrevious }) => {
  const { state } = useStateMachine({ updateForm });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex-1 mb-12">
      <div className="flex flex-col items-center">
        <h2 className="text-xl font-bold mb-3 mt-8">Product Photo</h2>
        <p className="mb-6 text-gray-500">
          Please upload your product images. Supported files are .jpg, .jpeg, or
          .png and the the recommended aspect ratio is 1:1 (square images).
        </p>
        <Controller
          name="images"
          control={control}
          defaultValue={state.formValues?.images}
          rules={{
            validate: (val) => (Array.isArray(val) ? val.length > 0 : !!val),
          }}
          render={({ field: { onChange, value } }) => (
            <ProductFileUpload onChange={onChange} value={value} />
          )}
        />
        {errors.images && errors.images.type === 'validate' && (
          <p className="text-red-600 text-xs">
            Please upload up to 5 product images
          </p>
        )}
      </div>
      <div className="flex justify-between">
        <Button
          title="Previous"
          onClick={onPrevious}
          type="button"
          mode="outlined"
          loadingColor="primary"
        />
        <Button title="Next" type="submit" />
      </div>
    </form>
  );
};

export default CreateProductUploadPhotosForm;
